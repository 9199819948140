import { ComponentProps } from "react";

import { HomeIcon, UserIcon } from "../../assets";
import { Menu as AnimatedMenu } from "./AnimatedMenu";
import { MenuDivider, MenuItemLink } from "./MenuItem";
import { ENABLE_MY_PROFILE_FEATURE } from "../../constants";

type AppMenuProps = ComponentProps<typeof AnimatedMenu>;

export const AppMenu = ({ children, ...props }: AppMenuProps) => (
  <AnimatedMenu {...props}>
    {ENABLE_MY_PROFILE_FEATURE && (
      <>
        <MenuItemLink
          explanation={`
        View past games you’ve played, your collection of player cards and more.
        `}
          Icon={UserIcon}
          to="/"
        >
          My Profile
        </MenuItemLink>
        <MenuDivider />
      </>
    )}
    {children}
    <MenuItemLink Icon={HomeIcon} to="/">
      Go Back Home
    </MenuItemLink>
  </AnimatedMenu>
);
