import { useEffect } from "react";

/**
 * Shows/hides the app level loading screen on mount and unmount.
 *
 * This allows for easy mount/unmount transitions.
 */
export const FullScreenLoadingAnimation = () => {
  /**
   * One possible improvement for the future would be to:
   *
   * 1. setup a context
   * 2. increment/decrement the number of active queries in ctx on mount
   * 3. hide/show based on currently loading count in the ctx
   *
   * It's also possible to use react-query's loading count, but that
   * is inherently global, and it's nice for this to be opt-in.
   */
  useEffect(() => {
    document.getElementById("app-loading-screen")?.classList.remove("loaded");

    return function onUnmount() {
      document.getElementById("app-loading-screen")?.classList.add("loaded");
    };
  }, []);
  return null;
};
