import { Box, BoxProps } from "@sidelinesports/rebass";

export type OverlayProps = Omit<BoxProps, "css">;

/**
 * An unstyled overlay. To style, supply:
 *
 * 1. a background color or gradient
 * 2. an (optional) opacity
 *
 * By default, the opacity is transitioned at 250ms.
 */
export const Overlay = ({ sx, ...props }: OverlayProps) => (
  <Box
    sx={{
      bottom: 0,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
      transition: "250ms opacity",
      willChange: "opacity",
      ...sx,
    }}
    {...props}
  />
);
