import { MouseEventHandler } from "react";

import {
  FullScreenErrorMessage,
  FullScreenLoadingAnimation,
  QUERY_STATUSES,
} from "../../components";
import { useGameQuery } from "./PlayerProfilePage.data";

import { PlayerProfile } from "pick3-fe-player-components";
import { usePlayersQuery } from "../game-dashboard/DashboardPage.data";

export const PlayerProfilePage = ({
  gameId,
  playerId,

  onClose,
}: {
  gameId: string;
  playerId: string;

  onClose: MouseEventHandler;
}) => {
  const { data: game, status: gameQueryStatus } = useGameQuery(gameId);
  const { data: players, status: playersQueryStatus } = usePlayersQuery();

  if (
    gameQueryStatus === QUERY_STATUSES.Idle ||
    gameQueryStatus === QUERY_STATUSES.Loading ||
    playersQueryStatus === QUERY_STATUSES.Idle ||
    playersQueryStatus === QUERY_STATUSES.Loading
  ) {
    return <FullScreenLoadingAnimation />;
  }

  if (
    gameQueryStatus === QUERY_STATUSES.Error ||
    playersQueryStatus === QUERY_STATUSES.Error ||
    !game ||
    !players
  ) {
    return <FullScreenErrorMessage />;
  }

  if (!game) return <FullScreenLoadingAnimation />;

  const player = players.find((player) => player.id === playerId);

  if (!player) {
    return <FullScreenErrorMessage />;
  }

  return <PlayerProfile player={player} onClose={onClose} />;
};
