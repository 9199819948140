import { useCallback } from "react";

import { Box, Text } from "@sidelinesports/rebass";

import { EditIcon } from "pick3-fe-icons";
import { PlayerCardBox } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { ROLLING_STONE, SHARK } from "pick3-theme";

export const PlayersEdit = ({ gameId }: { gameId: string }) => {
  const nav = useNavigate();

  const goToEditPlayers = useCallback(
    () => nav(`/games/${gameId}/select-players-edit`),
    [gameId, nav],
  );

  return (
    <PlayerCardBox
      onClick={goToEditPlayers}
      sx={{
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        color: ROLLING_STONE,
        cursor: "pointer",
      }}
    >
      <Box style={{ fontSize: "42px" }}>
        <EditIcon />
      </Box>
      <Text color={SHARK}>Change Picks</Text>
    </PlayerCardBox>
  );
};
