import { useState } from "react";
import { Flex } from "@sidelinesports/rebass";

import { ChevronRightIcon, StarCircle } from "../../../assets";
import { canShowPointBreakdown, PointBreakdownModal } from "../../../screens";

import {
  StatCard,
  StatCardTitle,
  StatCardNumberText,
  StatCardProps,
} from "./StatCard";
import {
  useScoringAndRankQuery,
  usePicksQuery,
  useGameQuery,
} from "./PointsCard.data";
import { IconButton, AnimatedNumber } from "../../../components";

type PointsCardProps = Omit<StatCardProps, "children" | "title"> & {
  gameId: string;
  points: number | null;
};

export const PointsCard = ({ gameId, points, ...props }: PointsCardProps) => {
  const { data: scoring } = useScoringAndRankQuery(gameId);
  const { data: myPicks } = usePicksQuery(gameId);
  const { data: game } = useGameQuery(gameId);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const canModalOpen =
    game &&
    scoring &&
    myPicks &&
    canShowPointBreakdown({ events: scoring?.events, picks: myPicks });

  /**
   * To prevent repaints with the adjacent button, get the approximate width of
   * the full points text.
   *
   * If it has delimiters (commas/dots), remove some portion of its length.
   */
  const pointsWidth =
    (points ?? 0).toLocaleString().length -
    ((points ?? 0).toLocaleString().length - (points ?? 0).toString().length) *
      0.2 +
    "ch";

  return (
    <>
      <StatCard
        style={{ cursor: "pointer" }}
        onClick={canModalOpen ? () => setIsModalOpen(true) : undefined}
        {...props}
      >
        <Flex flexDirection="row" alignItems="center">
          <Flex flexDirection="column" flex="1 0 auto">
            <StatCardTitle>Total Points</StatCardTitle>
            <StatCardNumberText>
              <StarCircle height="20px" fill={"white"} />
              <div
                style={{
                  display: "inline-block",
                  minWidth: pointsWidth,
                  paddingLeft: "6px",
                }}
              >
                <AnimatedNumber value={points ?? 0} />
              </div>
            </StatCardNumberText>
          </Flex>
          {canModalOpen && (
            <IconButton
              aria-label="View detailed point information."
              fontSize="32px"
              color="white"
              Icon={ChevronRightIcon}
            />
          )}
        </Flex>
      </StatCard>
      {canModalOpen && (
        <>
          <PointBreakdownModal
            sponsor={game!.gameSponsor || undefined}
            events={scoring?.events}
            picks={myPicks!}
            availableEvents={game!.availableEvents}
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
          />
        </>
      )}
    </>
  );
};
