/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject, useEffect, useRef } from "react";

const defaultEvents = ["mousedown", "touchstart"];

// Stolen from react-use
export const useClickAway = <E extends Event = Event>(
  ref: MutableRefObject<HTMLElement | undefined>,
  onClickAway: (event: E) => void,
  {
    active = true,
    events = defaultEvents,
  }: {
    active?: boolean;
    events?: string[];
  } = {},
) => {
  const savedCallback = useRef(onClickAway);

  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);

  useEffect(() => {
    if (active) {
      const handler = (event: any) => {
        const { current: el } = ref;

        if (el && !el.contains(event.target)) {
          savedCallback.current(event);
        }
      };

      for (const eventName of events) {
        on(document, eventName, handler);
      }

      return () => {
        for (const eventName of events) {
          off(document, eventName, handler);
        }
      };
    }
  }, [active, events, ref]);
};

const on = (obj: any, ...args: any[]) => obj.addEventListener(...args);

const off = (obj: any, ...args: any[]) => obj.removeEventListener(...args);
