import { ComponentType } from "react";
import { Image as DefaultImage, ImageProps } from "./Image";
import { AllowedRatioOrAuto } from "./ratios";
import { useResponsiveSizes } from "./use-responsive-sizes";
import {
  ResizeOptions,
  useResponsiveVariants,
} from "./use-responsive-variants";

type Source = {
  height: number;
  width: number;
  url: string;
};

export type ResponsiveImageProps = {
  alt: string;

  resize?: ResizeOptions & {
    ratio: AllowedRatioOrAuto;
  };

  /**
   * Source image. Variants are based off this.
   */
  source: Source;

  /**
   * Expected image width at each breakpoint. Used to set the image `size` property.
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/sizes
   */
  widths?: (number | string)[];

  /**
   * Image component (optional)
   */
  Image?: ComponentType<ImageProps>;
} & ImageProps;

export const ResponsiveImage = ({
  resize = { ratio: "auto" },
  source,
  Image = DefaultImage,
  ...props
}: ResponsiveImageProps) => {
  const { ratio, ...resizeOptions } = resize;
  const variants = useResponsiveVariants(source, {
    ratio,
    options: resizeOptions,
  });
  const widths = props.widths ?? inferWidthsFromImageProps(props);
  const sizes = useResponsiveSizes({
    widths: asArray(widths ?? props.width ?? props.maxWidth ?? "100%"),
  });

  const fallback = variants[0];

  return (
    <Image
      intrinsicHeight={source.height}
      intrinsicWidth={source.width}
      sizes={sizes}
      src={fallback.url}
      srcSet={toSrcSet(variants)}
      {...props}
    />
  );
};

function toSrcSet(sources: { url: string; width: number }[]) {
  return sources.map((source) => `${source.url} ${source.width}w`).join(",");
}

function inferWidthsFromImageProps(
  props: ImageProps,
  fallback = ["100%"],
): (number | string)[] {
  if (props.width) {
    return asArray(props.width) as (number | string)[];
  } else if (props.maxWidth) {
    return asArray(props.maxWidth) as (number | string)[];
  } else {
    return fallback;
  }
}

function asArray<T>(input: T | T[] | Record<string, T | undefined>): T[] {
  return Array.isArray(input)
    ? input
    : typeof input === "object"
      ? (Object.values(input as Record<string, T | undefined>).filter(
          (x) => x !== undefined,
        ) as T[])
      : [input];
}
