import { ApiAvailableEvent } from "../../../api";
import { Table } from "../../../components";

export const EventsTable = ({ events }: { events: ApiAvailableEvent[] }) => (
  <Table>
    <tbody>
      {events
        .sort((a, b) => a.order - b.order)
        .map((event) => (
          <Table.Row key={event.id}>
            <Table.Cell>{event.description}</Table.Cell>
            <Table.Cell fontWeight={600} textAlign="right">
              {event.points.toLocaleString()}
            </Table.Cell>
          </Table.Row>
        ))}
    </tbody>
  </Table>
);
