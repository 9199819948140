import { useCallback, useState } from "react";
import { Box } from "@sidelinesports/rebass";

import { ApiScoringEvent, ApiGameSponsor } from "../../../api";

import { BreakdownRowInner } from "./BreakdownRowInner";

export const BreakdownRow = ({
  event,
  sponsor,
}: {
  event: ApiScoringEvent & { powerupPoints: number };
  sponsor?: ApiGameSponsor;
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = useCallback(
    () => setExpanded(!expanded),
    [setExpanded, expanded],
  );

  return (
    <Box
      aria-expanded={expanded}
      key={event.id}
      onClick={toggleExpansion}
      style={{ marginBottom: "8px solid transparent" }}
      px="16px"
      py="12px"
      sx={{
        borderRadius: "8px",
        border: `1px solid #f0f0f0`,
        marginBottom: "8px",
        fontSize: "14px",
        boxShadow: expanded ? "0px 8px 24px -8px rgba(0, 0, 0, 0.08)" : "none",
      }}
    >
      <BreakdownRowInner event={event} sponsor={sponsor} expanded={expanded} />
    </Box>
  );
};
