import { PropsWithChildren, ReactElement } from "react";
import { Box, BoxProps, Heading, Image, Text } from "@sidelinesports/rebass";
import { ApiEndMessageCodeInfo } from "../../api";

type PrizeCodeProps = {
  info: ApiEndMessageCodeInfo & {
    code: NonNullable<ApiEndMessageCodeInfo["code"]>;
  };
};

export const PrizeCode = ({
  info,
  ...props
}: PrizeCodeProps & Omit<BoxProps, "css">) => (
  <CodeBox {...props}>
    {info.codeType === "alphanumeric" && (
      <Text variant="smallBody">Your win code is</Text>
    )}
    {renderCode(info)}
    {info.codeType === "alphanumeric" && (
      <Text variant="smallBody">Take a screenshot of this to use later!</Text>
    )}
  </CodeBox>
);

/**
 * Padded box with dashed border that goes around the content
 */
const CodeBox = ({ sx, ...props }: Omit<BoxProps, "css">) => (
  <Box
    p="12px"
    sx={{
      textAlign: "center",
      ...sx,
    }}
    width="100%"
    {...props}
  ></Box>
);

const renderCode = (info: PrizeCodeProps["info"]): ReactElement => {
  // use switch statement in case we add more code types in future
  switch (info.codeType) {
    case "alphanumeric":
      return <AlphanumericCode>{info.code}</AlphanumericCode>;
    case "barcode":
      return <Barcode code={info.code} barcodeType={info.barcodeType} />;
  }
};

/**
 * Render an alphanumeric code as plain text
 */
const AlphanumericCode = (props: PropsWithChildren<unknown>) => (
  <Heading my="8px" variant="h100">
    {props.children}
  </Heading>
);

/**
 * Render a barcode along with the code underneath in smaller plain text
 */
const Barcode = ({
  code,
  barcodeType,
}: {
  code: string;
  barcodeType: string;
}) => {
  const BARCODE_HEIGHT = 50;
  const BARCODE_WIDTH_NORMAL = 2;
  // gives worse barcode readability, but lets a wide code fit on screen
  const BARCODE_WIDTH_THIN = 1;

  const width = code.length < 12 ? BARCODE_WIDTH_NORMAL : BARCODE_WIDTH_THIN;

  const url = new URL(
    `/barcodes/${encodeURIComponent(code)}`,
    window.location.origin,
  );

  // do not display the code in monospace font underneath
  url.searchParams.set("displayValue", "false");
  url.searchParams.set("format", barcodeType);
  url.searchParams.set("height", BARCODE_HEIGHT.toString());
  url.searchParams.set("width", width.toString());

  return (
    <>
      <Image src={url.toString()} />
      <Heading mt="-6px" mb="8px" textAlign="center" variant="h300">
        {code}
      </Heading>
    </>
  );
};
