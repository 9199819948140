import { useState, useCallback } from "react";

import { Button } from "@sidelinesports/rebass";

import {
  ApiGameDetails,
  getEndMessage,
  getGamesResultViewed,
} from "../../../api";

import { useQuery } from "react-query";
import { useSetting } from "../../../settings";
import { GameFinishModalShower } from "./GameFinishModalShower";

function useEndMessage(gameId: string) {
  return useQuery([gameId, "end-message"], () => getEndMessage(gameId), {
    refetchInterval: useSetting("queries.polling.long"),
  });
}
function useMissedGameIds() {
  return useQuery(["games-missed-win"], getGamesResultViewed, {
    refetchInterval: useSetting("queries.polling.long"),
  });
}

export const ViewResultButton = ({
  game,
  position,
}: {
  game: ApiGameDetails;
  position: number;
}) => {
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const handleOpenModal = useCallback(
    () => setFinishModalOpen(true),
    [setFinishModalOpen],
  );

  const { data: endMessage } = useEndMessage(game.id);
  const { data: missedGameIds } = useMissedGameIds();

  if (!endMessage || !missedGameIds) {
    return null;
  }

  const missedWinningMessage = missedGameIds.includes(game.id);

  return (
    <>
      <Button
        onClick={handleOpenModal}
        backgroundColor="secondary"
        color="secondaryReadable"
        display="block"
        minWidth="130px"
        minHeight="33px"
        sx={{
          fontSize: "12px",
          letterSpacing: "0.8px",
          borderRadius: "17px",
          cursor: "pointer",
          fontWeight: "500",
          textTransform: "uppercase",
          border: "1px solid rgba(255,255,255,0.25)",
        }}
        width="auto"
      >
        View Result
      </Button>
      <GameFinishModalShower
        game={game}
        endMessage={endMessage}
        missedWinningMsg={missedWinningMessage}
        position={position}
        manualOpen={finishModalOpen}
        setManualOpen={setFinishModalOpen}
      />
    </>
  );
};
