import { useMemo, ReactElement } from "react";

import { useLocalStorage } from "@rehooks/local-storage";
import { useQuery } from "react-query";

import { getAppConfig, ApiAppConfig, ApiUploadedImage } from "../../api";
import { ThemeProvider } from "@emotion/react";
import { getTheme } from "pick3-theme";
import { GlobalCSS } from "./GlobalCSS";
import { useTenantSetting } from "../../hooks";
import { useSetting } from "../../settings";

import { AppDetailsProvider } from "./AppDetails";

/**
 * Renders children inside a ThemeProvider. If a tenant is present, the tenant
 * config is asynchronously fetched.
 *
 * If a tenant is not present, generic colors are used.
 */
export const AppLayout = (props: { children: ReactElement }) => {
  const [tenant] = useTenantSetting();

  return tenant ? (
    <AppLayoutWithTenantTheme key={tenant} tenant={tenant} {...props} />
  ) : (
    <AppLayoutWithTheme
      primary="#0b5571"
      secondary="#aaaa33"
      appName="Pick 3"
      splashImage={null}
      teamName={null}
      teamImage={null}
      timezone={"America/New_York"}
      footers={[]}
      {...props}
    />
  );
};

const AppLayoutWithTenantTheme = ({
  tenant,
  ...props
}: {
  tenant: string;
  children: ReactElement;
}) => {
  const { data: config, isError } = useCachedConfig(tenant);

  /*
   * If there is no error, we're just loading the
   * app them / config for the first time, so
   * just render the GlobalCSS.
   *
   * But, if we have an error, lower levels of the
   * react-hierarchy might want to show a message /
   * change something to fix the error, so render
   * the lower levels and allow them to do so.
   */
  if (!config) {
    if (!isError) {
      return <GlobalCSS />;
    } else {
      return (
        <AppLayoutWithTheme
          primary="#0b5571"
          secondary="#aaaa33"
          appName="Pick 3"
          splashImage={null}
          teamName={null}
          teamImage={null}
          timezone={"America/New_York"}
          footers={[]}
          {...props}
        />
      );
    }
  }

  const {
    primaryColor: primary,
    secondaryColor: secondary,
    appName,
    splashImage,
    timezone,
    footers,
  } = config.theme;

  return (
    <AppLayoutWithTheme
      primary={primary}
      secondary={secondary}
      appName={appName}
      splashImage={splashImage ?? null}
      teamName={config.team.name}
      teamImage={config.team.logo ?? null}
      timezone={timezone}
      footers={footers}
      {...props}
    />
  );
};

/**
 * Returns global CSS and children wrapped in a ThemeProvider.
 *
 * Note: This is separated from AppLayout to allow for a conditional call to
 * `useMemo`.
 */
const AppLayoutWithTheme = ({
  primary,
  secondary,
  appName,
  splashImage,
  teamName,
  teamImage,
  timezone,
  footers,
  ...props
}: {
  children: ReactElement;
  primary: string;
  secondary: string;
  appName: string | null;
  splashImage: ApiUploadedImage | null;
  teamName: string | null;
  teamImage: ApiUploadedImage | null;
  timezone: string;
  footers: { order: number; text: string; hyperlink: string }[];
}) => {
  const theme = useMemo(
    () => getTheme({ primary, secondary }),
    [primary, secondary],
  );

  return (
    <AppDetailsProvider
      values={{ appName, splashImage, timezone, teamName, teamImage, footers }}
    >
      <GlobalCSS primary={primary} secondary={secondary} />
      <ThemeProvider theme={theme} {...props} />
    </AppDetailsProvider>
  );
};

function useCachedConfig(tenant: string) {
  const [cachedConfig, writeCachedConfig] = useLocalStorage(
    `Theme (${tenant})`,
  );

  return useQuery(["theme", tenant], getAppConfig, {
    initialData: !cachedConfig
      ? undefined
      : typeof cachedConfig === "string"
        ? (JSON.parse(cachedConfig) as unknown as ApiAppConfig)
        : (cachedConfig as unknown as ApiAppConfig),
    refetchInterval: useSetting("queries.polling.very-long"),
    onSuccess(data) {
      writeCachedConfig(JSON.stringify(data));
    },
  });
}
