import { DateTime } from "luxon";

/**
 * Takes timestamp and tries to convert it to
 * 12hr (am/pm) form.
 *
 * @example
 *
 * ```ts
 * to12HrTime("06:00"); // "6:00 AM"
 * to12HrTime("18:00"); // "6:00 PM"
 * ```
 */
export function to12HrTime(timestamp: string) {
  const time = DateTime.fromISO(timestamp);
  return time.toLocaleString(DateTime.TIME_SIMPLE);
}
