import { Flex, Text } from "@sidelinesports/rebass";

import { ApiGameDetails } from "../../../api";
import { Abbreviation } from "../../../components";
import { getShortTitle } from "../../../shared";

import { GameTimeIndicator } from "./GameTimeIndicator";

export const PageHeaderGameInfo = ({ game }: { game: ApiGameDetails }) => (
  <Flex flexDirection="column">
    <Text
      mr="8px"
      variant="h300"
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingBottom: "2px",
      }}
    >
      <GameShortTitle game={game} />
    </Text>
    <GameTimeIndicator game={game} />
  </Flex>
);

const GameShortTitle = ({ game }: { game: ApiGameDetails }) => {
  const { title, abbreviation = title } = getShortTitle(game);

  return (
    <Abbreviation fontSize="18px" lineHeight="22px" title={title}>
      {abbreviation}
    </Abbreviation>
  );
};
