import { useContext } from "react";
import { Box, BoxProps } from "@sidelinesports/rebass";
import { Toast } from "./Toast";
import { useTimeout } from "../../hooks";
import { CurrentToastContext } from "./ToastsProvider";

export const Toasts = () => {
  useToastAutoClear();

  return (
    <ToastsBox>
      <CurrentToast />
    </ToastsBox>
  );
};

const ToastsBox = (props: Omit<BoxProps, "css">) => (
  <Box
    sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
    {...props}
  />
);

const CurrentToast = () => {
  const { toast, remove: onClose } = useContext(CurrentToastContext);

  if (!toast) {
    return null;
  }

  return (
    <Toast message={toast.message} title={toast.title} onClose={onClose} />
  );
};

/**
 * Hides the current toast after its duration has passed.
 */
function useToastAutoClear() {
  const { toast, remove } = useContext(CurrentToastContext);
  const duration = toast?.duration ?? 0;

  // Treat duration=0 as infinite (i.e., user action required)
  const delay = duration > 0 ? duration : null;

  useTimeout(remove, delay);
}
