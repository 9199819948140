import { useState, useEffect, useCallback } from "react";
import { ApiEndMessage, ApiGameDetails, GameStatus } from "../../../api";
import { GameFinishModal } from "../../../screens/game-finish-modal";

/**
 * Handles showing/hiding of the GameFinish modal, based on the state of
 * the game (whether or not it's finished) and whether or not the user has
 * viewed this modal already.
 */
export const GameFinishModalShower = ({
  game,
  position,
  endMessage,
  manualOpen,
  setManualOpen,
  missedWinningMsg,
}: {
  game: ApiGameDetails;
  endMessage: ApiEndMessage;
  position: number;
  manualOpen: boolean;
  setManualOpen: (x: boolean) => void;
  missedWinningMsg: boolean;
}) => {
  const gameId = game.id;
  const gameStatus = game.status;

  const [isAutoOpened, setIsAutoOpened] = useState(
    shouldShowModal(gameId, gameStatus, missedWinningMsg),
  );

  useEffect(
    function maybeShowModalOnStatusChange() {
      if (shouldShowModal(gameId, gameStatus, missedWinningMsg)) {
        setIsAutoOpened(true);
      }
    },
    [gameId, gameStatus, missedWinningMsg],
  );

  const onModalClose = useCallback(() => {
    setIsAutoOpened(false);
    setManualOpen(false);
    window.localStorage.setItem(getKey(gameId), "yes");
  }, [gameId, setManualOpen, setIsAutoOpened]);

  return (
    <GameFinishModal
      endMessage={endMessage}
      game={game}
      isOpen={isAutoOpened || manualOpen}
      onRequestClose={onModalClose}
      position={position}
    />
  );
};

const shouldShowModal = (
  gameId: string,
  status: GameStatus,
  missedWinningMsg: boolean,
) => {
  if (missedWinningMsg) {
    return true;
  }
  return status === "finished" && !hasViewedMessage(gameId);
};

const hasViewedMessage = (gameId: string) =>
  window.localStorage.getItem(getKey(gameId)) === "yes";

const getKey = (gameId: string) => `Post-Game Message (${gameId})`;
