import { useQuery } from "react-query";

import { getPicksByGameId, getGameById, getScoringAndRank } from "../../../api";
import { queryClient } from "../../../components";
import { useSetting } from "../../../settings";

export function usePicksQuery(gameId: string) {
  return useQuery([gameId, "picks"], () => getPicksByGameId(gameId));
}

export function useScoringAndRankQuery(gameId: string) {
  return useQuery(
    [gameId, "scoring-and-rank"],
    () => getScoringAndRank(gameId),
    {
      refetchInterval: useSetting("queries.polling.short"),
    },
  );
}

export function useGameQuery(gameId: string) {
  return useQuery([gameId, "games"], () => getGameById(gameId), {
    refetchInterval: useSetting("queries.polling.very-long"),
  });
}

export async function prefetch(gameId: string) {
  return Promise.all([
    queryClient.prefetchQuery([gameId, "picks"], () =>
      getPicksByGameId(gameId),
    ),
    queryClient.prefetchQuery([gameId, "scoring-and-rank"], () =>
      getScoringAndRank(gameId),
    ),
    queryClient.prefetchQuery([gameId, "games"], () => getGameById(gameId)),
  ]);
}
