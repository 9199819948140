import { DateTime } from "luxon";

export const getShortTZ = () => {
  return DateTime.local().offsetNameShort;
};

export const getDateString = (timestamp: string, format: string = "LLL dd") => {
  const date = DateTime.fromISO(timestamp);
  return date.toFormat(format);
};

export const getDateStringOrToday = (
  timestamp: string,
  format: string = "LLL dd",
) => {
  // Takes an ISO date string, ignores the time
  // and returns either
  // -- if today, "today"
  // -- if something else "Month, Day"
  const date = DateTime.fromISO(timestamp);
  if (isToday(date)) {
    return "today";
  } else {
    return date.toFormat(format);
  }
};

const isToday = (someDate: DateTime) => {
  const today = DateTime.local();
  return (
    someDate.day === today.day &&
    someDate.month === today.month &&
    someDate.year === today.year
  );
};
