import { Text, TextProps } from "@sidelinesports/rebass";

export const Abbreviation = ({ sx, ...props }: Omit<TextProps, "css">) => (
  <Text
    as="abbr"
    sx={{
      // needs added specificity to override abbr[title] in normalize.css
      "&[title]": {
        textDecoration: "none",
      },
      ...sx,
    }}
    {...props}
  />
);
