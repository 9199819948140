import { MouseEventHandler } from "react";

import { Box, Text } from "@sidelinesports/rebass";
import { CancelIcon } from "../../assets";
import { Abbreviation, IconButton } from "../../components";
import { SCREEN_GUTTER_TOP, SCREEN_GUTTER_X } from "../../constants";
import { getDateString, getShortTitle } from "../../shared";
import { ApiGameDetails } from "../../api";
import { ROLLING_STONE } from "pick3-theme";

export const GameFinishCloseButton = ({
  onClick,
  game,
}: {
  onClick?: MouseEventHandler;
  game: ApiGameDetails;
}) => (
  // Note: The hidden button on the left
  // so game date will be exactly centered,
  // balanced against space taken up by the
  // close button on the right
  <Box
    mt={SCREEN_GUTTER_TOP}
    mx={SCREEN_GUTTER_X}
    sx={{
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Box style={{ visibility: "hidden" }}>
      <IconButton aria-label="" fontSize={36} Icon={CancelIcon} />
    </Box>
    <Box>
      <GameDate game={game} />
    </Box>
    <IconButton
      aria-label="Close Message"
      fontSize={36}
      Icon={CancelIcon}
      onClick={onClick}
    />
  </Box>
);

export const GameDate = ({ game }: { game: ApiGameDetails }) => {
  const { title, abbreviation = title } = getShortTitle(game);
  return (
    <Text color={ROLLING_STONE} variant="smallBody">
      <Abbreviation title={title}>{abbreviation}</Abbreviation> •{" "}
      {getDateString(game.timestamp, "M/dd/yyyy")}
    </Text>
  );
};
