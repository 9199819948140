import { ApiPick, ApiScoringEvent } from "../../api";

export function canShowPointBreakdown({
  events,
  picks,
}: {
  events: ApiScoringEvent[];
  picks: ApiPick[];
}) {
  const playerIds = picks.map((pick) => pick.playerId);

  const myScoringEvents = events.filter((event) =>
    playerIds.includes(event.playerId),
  );

  return myScoringEvents.length > 0;
}
