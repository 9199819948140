export const ALLOWED_RATIOS = [
  "1:1",
  "2:3",
  "3:2",
  "3:4",
  "4:3",
  "4:5",
  "4:9",
  "5:4",
  "9:4",
  "9:16",
  "16:9",
] as const;

export type AllowedRatio = (typeof ALLOWED_RATIOS)[number];
export type AllowedRatioOrAuto = AllowedRatio | "auto";

export function calculateWidth(height: number, ratio: AllowedRatio) {
  return Math.round(height * ratioToFloat(ratio));
}

export function calculateHeight(width: number, ratio: AllowedRatio) {
  return Math.round(width / ratioToFloat(ratio));
}

export function getVariantDimensions(
  source: { height: number; width: number },
  request: { height?: number; width?: number; ratio?: AllowedRatio },
) {
  const ratio = request.ratio
    ? ratioToFloat(request.ratio)
    : source.width / source.height;

  const width =
    request.width ??
    (request.height != null ? Math.round(request.height / ratio) : null) ??
    800;

  const height = width / ratio;

  return { height, width };
}

export const ratioToFloat = (ratio: AllowedRatio) => {
  const [numerator, denominator] = ratio
    .split(":")
    .map((number) => parseInt(number, 10));

  return numerator / denominator;
};
