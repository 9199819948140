// This is a workaround to make the global object available in the window
// object. This is required for @rehooks/local-storage.
// Source: https://stackoverflow.com/a/73208485
/* eslint-disable import/first -- We need to patch window.global first */
window.global ||= window;

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { isMLB } from "./vendor/is-mlb";

const rootElement = document.getElementById("root");

async function main() {
  if (isMLB()) {
    import("./vendor/bam-hooks").then(() =>
      import("./vendor/attach-bam-hooks").then(({ register }) => register()),
    );
  }

  start();
}

function start() {
  if (rootElement) {
    createRoot(rootElement).render(
      <StrictMode>
        <App />
      </StrictMode>,
    );
  }

  serviceWorker.unregister();
}

main();
