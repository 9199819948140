import { useRef, ComponentType, useLayoutEffect, useState } from "react";

import BaseModal, { Props } from "react-modal";

export type ModalProps = Props & {
  background?: string;
  justifyContent?: string;
};
export type ModalComponentType = ComponentType<ModalProps>;

export const Modal = (props: ModalProps) => {
  const [wasOpen, setWasOpen] = useState(false);
  const scrollRef = useRef(0);

  /**
   * When the modal opens, set overflow: hidden on the body
   * to prevent scrolling in the background (and also to prevent
   * a double scrollbar in Chrome).
   *
   * That resets scrollTop to 0, so restore the original scroll position
   * when the modal closes.
   */
  useLayoutEffect(() => {
    const opening = props.isOpen;
    if (opening) {
      scrollRef.current = window.scrollY;
      document.body.style.overflow = "hidden";
      setWasOpen(true);
    } else {
      // We only want to enable the scrollbar
      // now if at some point we were open -- i.e.,
      // don't enable it if we're currently closed
      // and have never been open.
      if (wasOpen) {
        window.scrollTo(0, scrollRef.current);
        document.body.style.overflow = "auto";
        setWasOpen(false);
      }
    }
    return () => {
      window.scrollTo(0, scrollRef.current);
      document.body.style.overflow = "auto";
    };
    // eslint-disable-next-line
  }, [props.isOpen]);

  return <BaseModal {...props} />;
};
