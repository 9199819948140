import { ReactElement, FunctionComponent } from "react";
import { Box } from "@sidelinesports/rebass";

import { ApiPlayerDetails, GameStatus, ApiGameDetails } from "../../../../api";
import { SCREEN_GUTTER_X } from "../../../../constants";
import { ScoringResult } from "../../../../logic";
import { PlayersCard } from "./PlayersCard";
import { PlayersEdit } from "./PlayersEdit";

export type PlayerCardsSectionProps = {
  game: Pick<ApiGameDetails, "id" | "status">;
  players: Array<ApiPlayerDetails & { powerupPoints: number }>;
  points: Array<{ applicable: ScoringResult; playerId: string }>;
};

/**
 * Displays player cards in a horizontal row.
 *
 * On small screens, the cards go off the screen and are scrollable.
 *
 * On large screens, the cards appear centered in the content area.
 */
export const Players = ({
  game: { id: gameId, status: gameStatus },
  players,
  points,
}: PlayerCardsSectionProps) => {
  return (
    <Box
      // Add padding to the container so a bit of padding appears above the
      // scrollbar (especially on iOS)
      pb="8px"
      px={[SCREEN_GUTTER_X[0], SCREEN_GUTTER_X[1], 0]}
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${
          gameStatus === "about_to_start" ? 4 : 3
        }, 200px)`,
        gridGap: ["8px", "12px", "16px"],
        overflowX: "scroll",
        scrollSnapType: "mandatory",
      }}
    >
      {players.map((player) => (
        <PlayersBox key={player.id}>
          <PlayersCard
            key={player.id}
            gameId={gameId}
            player={player}
            powerupPoints={player.powerupPoints}
            points={
              hasStarted(gameStatus)
                ? getTotalPointsByPlayer(points, player.id)
                : null
            }
          />
        </PlayersBox>
      ))}
      {gameStatus === "about_to_start" && (
        <PlayersBox>
          <PlayersEdit gameId={gameId} />
        </PlayersBox>
      )}
    </Box>
  );
};

const hasStarted = (status: GameStatus) =>
  status === "in_progress" || status === "finished";

const getTotalPointsByPlayer = (
  points: PlayerCardsSectionProps["points"],
  playerId: string,
) =>
  points.find((subset) => subset.playerId === playerId)?.applicable?.total ??
  null;

const PlayersBox: FunctionComponent<{
  children?: ReactElement | ReactElement[];
}> = ({ children }) => {
  return (
    <Box
      width="100%"
      sx={{
        position: "relative",
        scrollSnapAlign: "start",
      }}
    >
      {children}
    </Box>
  );
};
