import { useEffect } from "react";
import { ApiEndMessage, ApiGameDetails, createEndMessageView } from "../../api";
import { ContentArea, FullScreenModal, ModalProps } from "../../components";
import { GameFinishCloseButton } from "./GameFinishCloseButton";
import { GameFinishMessage } from "./GameFinish";

export const GameFinishModal = ({
  endMessage,
  game,
  position,
  ...props
}: ModalProps & {
  endMessage: ApiEndMessage;
  game: ApiGameDetails;
  position: number;
}) => {
  useEffect(() => {
    if (props.isOpen) {
      createEndMessageView(game.id);
    }
  }, [props.isOpen, game.id]);

  return (
    <FullScreenModal justifyContent="center" {...props}>
      <GameFinishCloseButton onClick={props.onRequestClose} game={game} />
      <ContentArea
        display="flex"
        height="100%"
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <GameFinishMessage
          endMessage={endMessage}
          game={game}
          onClose={props.onRequestClose}
          position={position}
        />
      </ContentArea>
    </FullScreenModal>
  );
};
