import { useCallback, useEffect, useRef } from "react";
import { useTracking } from "./TrackingProvider";
import { useInterval } from "react-use";

const PING_INTERVAL = 60 * 1000;

export function useActivityTracking(track: ReturnType<typeof useTracking>) {
  const isUserActiveWithin = useIsUserActive();

  useEffect(() => {
    const handleLinkClick = (event: MouseEvent) => {
      if (!(event.target instanceof Element)) {
        return;
      }

      const link = event.target.closest("a");

      if (!link) {
        return;
      }

      const href = link.getAttribute("href");
      const text = link.innerText || link.getAttribute("aria-label") || "";

      const isSameDomain = href?.startsWith(window.location.origin);

      if (isSameDomain) {
        track("LINK_CLICK", {
          href: href,
          text: text,
          ...getEventContext(link),
        });
      } else {
        track("EXTERNAL_LINK_CLICK", {
          href: href,
          text: text,
          ...getEventContext(link),
        });
      }
    };

    document.addEventListener("click", handleLinkClick);

    return () => {
      document.removeEventListener("click", handleLinkClick);
    };
  }, [track]);

  useEffect(() => {
    const handleButtonClick = (event: MouseEvent) => {
      if (!(event.target instanceof Element)) {
        return;
      }

      const button = event.target.closest("button");

      if (!button) {
        return;
      }

      const text = button.innerText || button.getAttribute("aria-label") || "";

      track("BUTTON_CLICK", {
        text: text,
        ...getEventContext(button),
      });
    };

    document.addEventListener("click", handleButtonClick);

    return () => {
      document.removeEventListener("click", handleButtonClick);
    };
  }, [track]);

  useInterval(() => {
    // If the user is active, ping the server to keep the session alive.
    if (isUserActiveWithin(PING_INTERVAL)) {
      track("PING");
    }
  }, PING_INTERVAL);
}

function useIsUserActive() {
  // A user is active if they have taken any of the following actions within the
  // threshold (in milliseconds).
  //
  // - Clicked anything
  // - Typed anything
  // - Moved the mouse or cursor

  const lastActionTimestampRef = useRef<number>(Date.now());

  useEffect(() => {
    const handleAction = () => {
      lastActionTimestampRef.current = Date.now();
    };

    document.addEventListener("click", handleAction);
    document.addEventListener("keydown", handleAction);
    document.addEventListener("mousemove", handleAction);

    return () => {
      document.removeEventListener("click", handleAction);
      document.removeEventListener("keydown", handleAction);
      document.removeEventListener("mousemove", handleAction);
    };
  }, []);

  return useCallback(
    (thresholdMs: number) =>
      Date.now() - lastActionTimestampRef.current < thresholdMs,
    [],
  );
}

/**
 * Returns a function that will recursively search for the given property on
 * an element and its parents.
 */
function getPropertyRecursive(property: string) {
  return (element: Element | null): string | null => {
    const propertyValue = element?.getAttribute(property);

    if (propertyValue) {
      return propertyValue;
    }

    if (element?.parentElement) {
      return getPropertyRecursive(property)(element.parentElement);
    }

    return null;
  };
}

const getPageName = getPropertyRecursive("data-page-name");
const getSectionName = getPropertyRecursive("data-section-name");
const getDialogName = getPropertyRecursive("data-dialog-name");

function getEventContext(element: Element | null) {
  const pageName = getPageName(element);
  const sectionName = getSectionName(element);
  const dialogName = getDialogName(element);

  return {
    // TODO: This is blank when the button is in a dialog or something else
    // rendered by a portal.
    ...(pageName ? { page: pageName } : {}),
    ...(sectionName ? { section: sectionName } : {}),
    ...(dialogName ? { dialog: dialogName } : {}),
  };
}
