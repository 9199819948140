import { MouseEventHandler } from "react";

import { Box } from "@sidelinesports/rebass";
import { CancelIcon } from "../../assets";
import { IconButton } from "../../components";
import { SCREEN_GUTTER_X } from "../../constants";

export const CloseButton = ({ onClick }: { onClick?: MouseEventHandler }) => (
  <Box
    mt={"16px"}
    mx={SCREEN_GUTTER_X}
    sx={{
      // place at end of line
      alignSelf: "flex-end",
    }}
  >
    <Box mr="-10px">
      <IconButton
        aria-label="Close Dialog"
        fontSize={32}
        Icon={CancelIcon}
        ml="auto"
        onClick={onClick}
      />
    </Box>
  </Box>
);
