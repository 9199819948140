import {
  cloneElement,
  MouseEvent,
  TouchEvent,
  EventHandler,
  ReactElement,
  ComponentType,
  useState,
  useCallback,
} from "react";

import { ModalProps as BaseModalProps } from "../../../components";

type ModalToggleProps = {
  children: ReactElement<{
    onClick?: EventHandler<MouseEvent | TouchEvent>;
  }>;
  Modal: ComponentType<BaseModalProps>;
  isInitialOpen?: boolean;
} & Omit<BaseModalProps, "children" | "isOpen">;

/**
 * One caveat with this component: you can run into perf/animation issues if you
 * have an inline component function.
 *
 * In those cases, instead of
 *
 * ```jsx
 * <ModalToggle Modal={props => (
 *   <GameModal game={game} {...props} />
 * )} />
 * ```
 *
 * try
 *
 * ```jsx
 * const Modal = useMemo(() => (props: ModalProps) => (
 *   <GameModal game={game} {...props} />
 * ), [game]);
 *
 * <ModalToggle Modal={Modal} />
 * ```
 */
export function ModalToggle({
  children: button,
  isInitialOpen = false,
  Modal,
  onRequestClose,
  ...props
}: ModalToggleProps) {
  const [isOpen, setIsOpen] = useState(isInitialOpen);

  const onClose = useCallback(
    (e: Parameters<NonNullable<typeof onRequestClose>>[0]) => {
      e.preventDefault();

      setIsOpen(false);
      onRequestClose?.(e);
    },
    [onRequestClose],
  );

  const onOpen = useCallback(() => setIsOpen(true), []);

  return (
    <>
      {cloneElement(button, { onClick: onOpen })}
      <Modal isOpen={isOpen} onRequestClose={onClose} {...props} />
    </>
  );
}
