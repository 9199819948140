import { ComponentType } from "react";

import { ConditionalFullScreenModal, ModalProps } from "../../components";
import { PlayerProfilePage } from "./PlayerProfilePage";

type PlayerProfileModalProps = {
  Modal?: ComponentType<ModalProps>;

  gameId: string;
  playerId: string;
} & ModalProps;

export const PlayerProfileModal = ({
  Modal = ConditionalFullScreenModal,
  gameId,
  playerId,
  ...props
}: PlayerProfileModalProps) => (
  <Modal background="#F0F0F0" justifyContent="flex-start" {...props}>
    <PlayerProfilePage
      gameId={gameId}
      playerId={playerId}
      onClose={props.onRequestClose!}
    />
  </Modal>
);
