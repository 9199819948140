import styled from "@emotion/styled";
import { Modal } from "./Modal";

export const FULL_SCREEN_MODAL_BREAK = "540px";

export const ConditionalFullScreenModal = styled(Modal)`
  background: ${({ background = "white" }) => background};
  min-height: 100%;
  overflow: auto;

  outline: none;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: ${({ justifyContent = "center" }) => justifyContent};

  @media (min-width: ${FULL_SCREEN_MODAL_BREAK}) {
    border-radius: 16px 16px 0px 0px;
    max-width: 415px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 72px;
  }

  -webkit-overflow-scrolling: touch;
`;

ConditionalFullScreenModal.defaultProps = {
  style: {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.64)",
      overflow: "scroll",
      zIndex: 200,
    },
  },
};
