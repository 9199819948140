import ky from "ky";
import {
  setTenantHeader,
  setAuthorizationHeader,
  getTokenIfPermissionsError,
} from "./hooks";

export const getClient = ({
  authenticate = false,
}: {
  authenticate?: boolean;
} = {}) => (authenticate ? authenticatingClient : nonAuthenticatingClient);

const authenticatingClient = ky.create({
  credentials: "omit",
  retry: {
    limit: 2,
    statusCodes: [401, 408, 413, 429, 500, 502, 503, 504],
    methods: ["get", "post", "put", "head", "delete", "options", "trace"],
  },
  hooks: {
    beforeRequest: [setTenantHeader, setAuthorizationHeader],
    beforeRetry: [getTokenIfPermissionsError],
  },
});

const nonAuthenticatingClient = ky.create({
  credentials: "omit",
  hooks: {
    beforeRequest: [setTenantHeader],
  },
});
