import { useEffect, useState, useMemo } from "react";

import { Globals } from "@react-spring/web";

import { usePrefersReducedMotion } from "./use-prefers-reduced-motion";
import { useFPS } from "./use-fps";
import { useInterval } from "pick3-fe-hooks";

/**
 * Enables or disables animations based on the browser prefers-reduced-motion
 * setting.
 */
export function useMaybeDisableAnimations() {
  const prefersReducedMotion = usePrefersReducedMotion();
  const isLowFPS = useIsLowFPS();

  useEffect(() => {
    Globals.assign({
      skipAnimation: isLowFPS || prefersReducedMotion,
    });
  }, [isLowFPS, prefersReducedMotion]);
}

const SLOW_FPS_THRESHOLD = 45;

function useIsLowFPS() {
  const [isLowFPS, setIsLowFPS] = useState(false);
  const getFPS = useFPS();

  useInterval(
    useMemo(() => {
      return () => {
        const isSlowNow = getFPS() < SLOW_FPS_THRESHOLD;

        setIsLowFPS(isSlowNow);
      };
    }, [getFPS]),
    3000,
  );

  return isLowFPS;
}
