import { Text, Flex, TextProps, Image, Box } from "@sidelinesports/rebass";
import { ROLLING_STONE, SHARK } from "pick3-theme";
import { LightningIcon } from "pick3-fe-icons";

import { ApiScoringEvent, ApiGameSponsor } from "../../../api";
import { getScoringEventSentence } from "../../../shared";

type BreakdownRowInnerProps = {
  event: ApiScoringEvent & { powerupPoints: number };
  sponsor?: ApiGameSponsor;
  expanded: boolean;
};

export const BreakdownRowInner = ({
  event,
  sponsor,
  expanded,
}: BreakdownRowInnerProps) => {
  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
        pb={expanded ? "11px" : "0px"}
      >
        <Text style={{ whiteSpace: "nowrap" }}>
          {getScoringEventSentence(event)}
        </Text>
        <Flex flexDirection="row" alignItems="center">
          <Text textAlign="right" fontWeight={600}>
            {event.gameEvent.points +
              event.gameEvent.bonusPoints +
              event.powerupPoints}
          </Text>
        </Flex>
      </Flex>
      {expanded && <ExpandedElement event={event} sponsor={sponsor} />}
    </Flex>
  );
};

const ExpandedElement = ({
  event,
  sponsor,
}: Omit<BreakdownRowInnerProps, "expanded">) => {
  return (
    <Flex
      flexDirection="row"
      style={{ borderTop: "1px solid #f0f0f0", paddingTop: "12px" }}
    >
      <Box width="25%" pr={2}>
        <Label>Event</Label>
        <Points>{event.gameEvent.points}</Points>
      </Box>
      {event.powerupPoints !== 0 && (
        <Box width="25%" pr={2}>
          <Label>Powerup</Label>
          <Flex flexDirection="row" alignItems="center" color="secondaryIfDark">
            <LightningIcon height="13px" width="13px" />
            <Points pl={2}>{event.powerupPoints}</Points>
          </Flex>
        </Box>
      )}
      {sponsor && event.gameEvent.bonusPoints !== 0 && (
        <Box width="50%" pr={2}>
          <Label>
            <Image
              src={sponsor!.image.url}
              style={{
                height: "14px",
                objectFit: "contain",
                verticalAlign: "middle",
                maxWidth: "50px",
              }}
            />{" "}
            Bonus
          </Label>
          <Points>+{event.gameEvent.bonusPoints}</Points>
        </Box>
      )}
    </Flex>
  );
};

const Label = (props: Omit<TextProps, "css">) => (
  <Text fontSize="12px" pb={"6px"} color={ROLLING_STONE} {...props} />
);

const Points = (props: Omit<TextProps, "css">) => (
  <Text fontSize="14px" fontWeight="600" color={SHARK} {...props} />
);
