import { Text } from "@sidelinesports/rebass";

import { Markdown as MarkdownRenderer } from "pick3-fe-components";
import { ExternalLink } from "./ExternalLink";

export const Markdown = ({
  dark = false,
  contents,
}: {
  dark?: boolean;
  contents: string;
}) => (
  <Text variant="body">
    <MarkdownRenderer
      dark={dark}
      markdown={contents}
      options={{ overrides: { a: { component: ExternalLink } } }}
    />
  </Text>
);

export const MarkdownSmaller = ({
  dark = false,
  contents,
}: {
  dark?: boolean;
  contents: string;
}) => (
  <Text variant="body">
    <MarkdownRenderer
      dark={dark}
      markdown={contents}
      options={{
        overrides: {
          a: { component: ExternalLink },
          p: { props: { fontSize: "12px" } },
        },
      }}
    />
  </Text>
);
