import { useState } from "react";
import { useTheme } from "@emotion/react";
import { SHARK } from "pick3-theme";

import { Text, Flex, TextProps } from "@sidelinesports/rebass";
import { ApiPlayerDetails } from "../../../../api";
import { PlayerCard, AnimatedNumber } from "../../../../components";
import { LightningIcon } from "../../../../assets";
import { CardOverlay } from "../../../../components/player-cards/CardOverlay";
import { PlayerProfileModal } from "../../../../screens/player-profile";

/**
 * Overlays points on top of the normal player card.
 */
export const PlayersCard = ({
  gameId,
  player,
  powerupPoints,
  points,
}: {
  gameId: string;
  player: ApiPlayerDetails;
  powerupPoints: number;
  points: number | null;
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const theme = useTheme();
  return (
    <>
      <PlayerProfileModal
        gameId={gameId}
        playerId={player.id}
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
      />
      <PlayerCard
        overlay={
          <>
            <CardOverlay />
            <Flex style={{ position: "absolute", right: 0, top: 0 }}>
              <Flex
                flexDirection="row"
                backgroundColor="#F0F0F0"
                alignItems="center"
                pb="3px"
                pt="2px"
                pl="4px"
                pr="3px"
                style={{ borderRadius: "0px 0px 0px 8px" }}
              >
                <LightningIcon
                  color={theme.colors.secondaryIfDark}
                  height="14px"
                  style={{ paddingRight: "1px" }}
                />
                <Text
                  fontSize="12px"
                  lineHeight="16px"
                  fontWeight="600"
                  color={SHARK}
                >
                  {powerupPoints}
                </Text>
              </Flex>
            </Flex>
          </>
        }
        middle={
          <Points mb="8px">
            {typeof points === "number" ? (
              <AnimatedNumber value={points} />
            ) : (
              "—"
            )}
          </Points>
        }
        player={player}
        onClick={() => setModalVisibility(true)}
        sx={{
          cursor: "pointer",
        }}
      />
    </>
  );
};

export const Points = ({ sx, ...props }: Omit<TextProps, "css">) => (
  <Text
    color="white"
    fontSize="24px"
    fontWeight="bold"
    lineHeight="28px"
    sx={{ alignSelf: "flex-end", position: "relative", ...sx }}
    {...props}
  />
);
