import { SCREEN_GUTTER_TOP, SCREEN_GUTTER_X } from "../../constants";
import { PrimaryGradientCard, PrimaryGradientCardProps } from "../cards";

/**
 * A top section with the primary gradient.
 */
export const PrimaryGradientHeader = ({
  sx,
  ...props
}: PrimaryGradientCardProps) => (
  <PrimaryGradientCard
    as="header"
    pt={SCREEN_GUTTER_TOP}
    px={SCREEN_GUTTER_X}
    sx={{
      border: "none",
      borderRadius: 0,
      ...sx,
    }}
    {...props}
  />
);
