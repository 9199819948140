import { ApiGameDetails } from "../../api";
import { ContentArea, FullScreenModal, ModalProps } from "../../components";
import { CloseButton } from "./CloseButton";
import { ScoreLegend } from "./ScoreLegend";

type ScoreLegendPageProps = {
  game: ApiGameDetails;
} & ModalProps;

export const ScoreLegendModal = ({ game, ...props }: ScoreLegendPageProps) => {
  return (
    <FullScreenModal justifyContent="flex-start" {...props}>
      <CloseButton onClick={props.onRequestClose} />
      <ContentArea>
        <ScoreLegend game={game} />
      </ContentArea>
    </FullScreenModal>
  );
};
