import { Box, BoxProps, Text, TextProps } from "@sidelinesports/rebass";

export const Table = ({ sx, ...props }: Omit<BoxProps, "css">) => (
  <Box as="table" sx={{ borderSpacing: 0, ...sx }} width="100%" {...props} />
);

Table.Row = ({ sx, ...props }: Omit<BoxProps, "css">) => (
  <Box
    as="tr"
    px="8px"
    py="12px"
    sx={{
      color: "inherit",
      "&:nth-child(odd)": {
        backgroundColor: "#F7F8F9",
      },
      "&:nth-child(even)": {
        backgroundColor: "white",
      },
      ...sx,
    }}
    width="100%"
    {...props}
  />
);

Table.Cell = (props: Omit<TextProps, "css">) => (
  <Text
    as="td"
    fontSize="14px"
    lineHeight="17px"
    px="16px"
    py="12px"
    {...props}
  />
);
