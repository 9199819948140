import { useMemo } from "react";
import { useTheme } from "@emotion/react";

/**
 * Generates a `sizes` attribute for use with an img tag. It assumes that
 * each of the widths corresponds with a specific breakpoint.
 *
 * ```@example
 * const sizes = useResponsiveSizes({
 *   getBreakpoints: () => ([ "200px", "400px" ]),
 *   sizes: [ 100vw, 50vw ]
 * });
 *
 * //= "(min-width: 400px) 50vw, 100vw"
 * ```
 */
export function useResponsiveSizes({
  getBreakpoints = useThemeBreakpoints,

  /** image width at each breakpoint */
  widths = ["100%"] as (number | string)[],
}) {
  const breakpoints = getBreakpoints();

  return useMemo(() => {
    return toImageSizes(breakpoints ?? [], widths);
  }, [breakpoints, widths]);
}

function useThemeBreakpoints() {
  const { breakpoints } = useTheme();

  return breakpoints;
}

function toImageSizes(breakpoints: string[], widths: (number | string)[]) {
  if (widths.length === 0) return undefined;

  const toQuery = ([breakpoint, width]: [string, string | number]) =>
    `(min-width: ${breakpoint}) ${width}`;

  return [
    ...zipCompact(breakpoints, widths.slice(1)).map(toQuery),
    widths[0],
  ].join(", ");
}

/**
 * Zips arrays of equal length
 */
function zipCompact<T, U>(left: T[], right: U[]) {
  const length = Math.min(left.length, right.length);

  return left
    .slice(0, length)
    .map((item, index): [T, U] => [item, right[index]]);
}
