import { useCallback, ReactElement, useState } from "react";

import { Button, ButtonProps } from "@sidelinesports/rebass";

import { KebobIcon } from "../../assets";
import { IconButton } from "../IconButton";
import { AppMenu } from "./AppMenu";

type MenuComponent = typeof AppMenu;

type MenuButtonProps = {
  Button?: typeof Button;
  Menu?: MenuComponent;
  children?: ReactElement;
} & Omit<ButtonProps, "css">;

export const MenuButton = ({
  Button = HamburgerButton,
  children,
  Menu = AppMenu,
  ...props
}: MenuButtonProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onMenuOpen = useCallback(() => setIsMenuOpen(true), []);
  const onMenuClose = useCallback(() => setIsMenuOpen(false), []);

  return (
    <nav>
      <Button
        aria-expanded={isMenuOpen}
        aria-haspopup="menu"
        type="button"
        onClick={onMenuOpen}
        onFocus={onMenuOpen}
        title="Open app menu"
        {...props}
      />
      <Menu isOpen={isMenuOpen} onRequestClose={onMenuClose}>
        {children}
      </Menu>
    </nav>
  );
};

const HamburgerButton = (props: Omit<ButtonProps, "css">) => (
  <IconButton
    aria-label="Open app menu"
    color="primaryReadable"
    display="flex"
    fontSize="42px"
    ml="auto"
    Icon={KebobIcon}
    {...props}
  />
);
