import { BeforeRequestHook } from "ky";

import { AUTH_TOKEN_KEY } from "../../../constants";
import { getMyToken } from "../../auth";

/**
 * Uses the token defined in local storage, if present, as the
 * Authorization header.
 */
export const setAuthorizationHeader: BeforeRequestHook = async (request) => {
  let token = window.localStorage.getItem(AUTH_TOKEN_KEY);

  if (!token) {
    token = await getMyToken();
    if (token) {
      window.localStorage.setItem(AUTH_TOKEN_KEY, token);
    } else {
      window.location.assign("/oops");
      return;
    }
  }

  request.headers.set("Authorization", `Bearer ${token}`);
};
