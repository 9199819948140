import { ApiScoringEvent } from "../../api";

/**
 * Formats a scoring event in sentence form.
 *
 * E.g., "J. Blow got a Home Run"
 */
export const getScoringEventSentence = (event: ApiScoringEvent) => {
  const { player } = event;

  const firstInitial = player.firstName.charAt(0);
  const shortName = `${firstInitial}. ${player.lastName}`;

  return `${shortName} - ${event.gameEvent.description}`;
};
