import { PropsWithChildren, useEffect, useRef } from "react";
import { animated, useSpring, config } from "@react-spring/web";
import { Box } from "@sidelinesports/rebass";
import { ContentArea } from "../ContentArea";
import { useClickAway } from "../../hooks";

export const Menu = ({
  children,
  isOpen,
  onRequestClose,
}: PropsWithChildren<{
  isOpen: boolean;
  onRequestClose: () => void;
}>) => {
  const menuRef = useRef<HTMLElement | undefined>();

  const spring = useSpring({
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,

    paddingTop: "5%",

    // rest at -10% so that the overshot from config.stiff doesn't reveal
    // the top edge of the menu
    transform: isOpen ? "translate3d(0, -10%, 0)" : "translate3d(0, -120%, 0)",

    config: config.stiff,
  });

  useEffect(
    function focusFirstMenuItemOnOpen() {
      if (isOpen && menuRef.current) {
        const firstLink: HTMLElement | null =
          menuRef.current.querySelector("[role=menuitem]");

        firstLink?.focus();
      }
    },
    [isOpen],
  );

  useClickAway(menuRef, onRequestClose, { active: isOpen });

  return (
    <animated.nav
      ref={(ref) => {
        ref && (menuRef.current = ref);
      }}
      role="menu"
      style={{
        background: "white",
        boxShadow: "0 8px 8px -8px rgba(0,0,0,0.08)",
        overflowX: "hidden",
        overflowY: "auto",
        position: "fixed",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(spring as any),
      }}
    >
      <ContentArea pb="24px" pt="24px">
        <Box display="grid" sx={{ rowGap: "24px" }}>
          {children}
        </Box>
      </ContentArea>
    </animated.nav>
  );
};
