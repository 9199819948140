import { useMemo } from "react";

import { useTheme } from "@emotion/react";
import { lighten, darken } from "polished";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

export const FinishConfetti = () => {
  const { height, width } = useWindowSize();

  const {
    colors: { primary, secondary },
  } = useTheme();

  const colors = useMemo(
    () => [
      primary,
      lighten(0.1, primary),
      lighten(0.2, primary),
      darken(0.1, primary),
      darken(0.2, primary),
      secondary,
      lighten(0.1, secondary),
      lighten(0.2, secondary),
      darken(0.1, secondary),
      darken(0.2, secondary),
    ],
    [primary, secondary],
  );
  return (
    <Confetti
      colors={colors}
      height={height}
      numberOfPieces={1500}
      recycle={false}
      tweenDuration={10000}
      width={width}
    />
  );
};
