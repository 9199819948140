import ky from "ky";
import { getClient } from "./client";

export async function getMyTenant() {
  // use base client to avoid the setTenant hook
  return ky
    .get(`/api/my/tenant`, { credentials: "include" })
    .json<{ subdomain: string }>()
    .then((tenant) => tenant.subdomain)
    .catch(() => null);
}
export async function getMyToken() {
  return getClient()
    .get(`/api/my/token`, { credentials: "include" })
    .text()
    .catch(() => null);
}

export async function postLogin({ id }: { id: string }) {
  return getClient()
    .post(`/api/auth/login`, {
      json: { id },
      credentials: "include",
    })
    .text();
}

export async function postDemoLogin(id: string) {
  return getClient()
    .post(`/api/auth/demo`, {
      json: { id },
      credentials: "include",
    })
    .text();
}
