import { ReactChild } from "react";

import {
  Box,
  BoxProps,
  Flex,
  Image,
  ImageProps,
  Link,
  Text,
} from "@sidelinesports/rebass";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Divider } from "../Divider";
import { ROLLING_STONE, SHARK } from "pick3-theme";

type MenuItemProps = {
  explanation?: ReactChild;
  Icon: ImageProps["as"];
} & Omit<BoxProps, "css">;

export const MenuItem = ({
  children,
  explanation,
  Icon,
  sx,
  ...props
}: MenuItemProps) => (
  <Flex alignItems="start" sx={{ cursor: "pointer", ...sx }} {...props}>
    <Image
      aria-hidden="true"
      as={Icon}
      color="#343536"
      height="24px"
      mr="16px"
      width="24px"
    />
    <Box sx={{ textAlign: "left" }}>
      <Text color={SHARK} fontSize="16px" fontWeight={600} lineHeight="24px">
        {children}
      </Text>
      {typeof explanation === "string" ? (
        <Text color={ROLLING_STONE} fontWeight="normal" variant="body">
          {explanation}
        </Text>
      ) : (
        explanation
      )}
    </Box>
  </Flex>
);

type MenuItemLinkProps = MenuItemProps & {
  to: RouterLinkProps["to"];
};

export const MenuDivider = () => <Divider />;

export const MenuItemLink = ({ children, to, ...props }: MenuItemLinkProps) => (
  <Link
    as={(props) => <RouterLink to={to} {...props} />}
    fontSize="16px"
    role="menuitem"
    sx={{
      color: "#343536",
      fontWeight: 600,
      lineHeight: "24px",
      outline: "none",
    }}
  >
    <MenuItem {...props}>{children}</MenuItem>
  </Link>
);
