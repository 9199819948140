import { BeforeRequestHook } from "ky";

import { TENANT_KEY } from "../../../constants";
import { getMyTenant } from "../../auth";

/**
 * Uses the tenant defined in local storage, if present, as the
 * Tenant header.
 */
export const setTenantHeader: BeforeRequestHook = async (request) => {
  let tenant = window.localStorage.getItem(TENANT_KEY);

  if (!tenant) {
    tenant = await getMyTenant();
    if (tenant) {
      window.localStorage.setItem(TENANT_KEY, tenant);
    } else {
      window.location.assign("/oops");
      return;
    }
  }

  request.headers.set("Tenant", tenant);
};
