import { BeforeRetryHook } from "ky";

import { AUTH_TOKEN_KEY } from "../../../constants";
import { getMyToken } from "../../auth";

/**
 * If we have an auth error, pull a new token
 * and use that before retrying.
 */
export const getTokenIfPermissionsError: BeforeRetryHook = async (data) => {
  if (isAuthorizationError(data)) {
    const token = await getMyToken();
    if (token) {
      window.localStorage.setItem(AUTH_TOKEN_KEY, token);
    } else {
      window.location.assign("/oops");
      return;
    }
  }
};

// Types for data returned by ky are incomplete
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAuthorizationError(data: any) {
  return data?.error?.response?.status === 401;
}
