import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import is from "@sindresorhus/is";
import throttle from "lodash/throttle";

const TRACK_BORDER_COLOR = "rgba(52,53,54,0.16)";
const DISABLED_THUMB_COLOR = "#7C7D7E";

/**
 * When active, scale thumb up by this amount
 */
const THUMB_SCALE_FACTOR = 1.2;

/**
 * Height/width for the thumb
 */
const THUMB_DIAMETER = "1.75em";

const BaseSlider = styled.input<{ percent: string }>`
  &[type="range"] {
    // disables default browser styling
    appearance: none;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
    width: 100%;

    // prevents highlighting of bar when active
    -webkit-tap-highlight-color: transparent;
    background: transparent;

    &:focus {
      outline: none;
    }

    &:active {
      &::-webkit-slider-thumb {
        // Use scale3d to prevent weird 1px jump at end
        transform: scale3d(${THUMB_SCALE_FACTOR}, ${THUMB_SCALE_FACTOR}, 1);
      }

      &::-ms-thumb {
        // Use scale3d to prevent weird 1px jump at end
        transform: scale3d(${THUMB_SCALE_FACTOR}, ${THUMB_SCALE_FACTOR}, 1);
      }

      &::-moz-range-thumb {
        // Use scale3d to prevent weird 1px jump at end
        transform: scale3d(${THUMB_SCALE_FACTOR}, ${THUMB_SCALE_FACTOR}, 1);
      }
    }

    &:disabled {
      &::-webkit-slider-thumb {
        background: ${DISABLED_THUMB_COLOR};
      }

      &::-moz-range-thumb {
        background: ${DISABLED_THUMB_COLOR};
      }

      &::-ms-thumb {
        background: ${DISABLED_THUMB_COLOR};
      }
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.4em;

      border-radius: 999px;

      background: ${TRACK_BORDER_COLOR};
      background: linear-gradient(
        to right,
        ${(props) => props.theme.colors.secondaryIfDark} 0%,
        ${(props) => props.theme.colors.secondaryIfDark}
          ${(props) => props.percent},
        ${TRACK_BORDER_COLOR} ${(props) => props.percent},
        ${TRACK_BORDER_COLOR} 100%
      );

      cursor: pointer;
    }

    &::-moz-range-track {
      width: 100%;
      height: 0.4em;

      border-radius: 999px;

      background: ${TRACK_BORDER_COLOR};

      cursor: pointer;
    }

    /**
     * Style the portion of the slider before the thumb
     **/

    &::-moz-range-progress {
      background: ${(props) => props.theme.colors.secondaryIfDark};
      border-radius: 999px;
      height: 0.4em;
    }

    &::-ms-fill-lower {
      background: ${(props) => props.theme.colors.secondaryIfDark};
      border-radius: 999px;
      height: 0.4em;
    }

    /**
     * Style the thumb
     **/

    &::-webkit-slider-thumb {
      // disables default browser styling
      appearance: none;

      height: ${THUMB_DIAMETER};
      width: ${THUMB_DIAMETER};

      margin-top: -0.75em;

      border: none;

      border-radius: 50%;
      outline: none;

      background: ${(props) => props.theme.colors.secondaryIfDark};

      cursor: grab;

      // Use scale3d to prevent weird 1px jump at end
      transform: scale3d(1, 1, 1);
      transform-origin: center;
      transition: transform 0.2s;
    }

    &::-moz-range-thumb {
      // disables default browser styling
      appearance: none;

      height: ${THUMB_DIAMETER};
      width: ${THUMB_DIAMETER};

      margin-top: -0.75em;

      border: none;

      border-radius: 50%;
      outline: none;

      background: ${(props) => props.theme.colors.secondaryIfDark};

      cursor: grab;

      // Use scale3d to prevent weird 1px jump at end
      transform: scale3d(1, 1, 1);
      transform-origin: center;
      transition: transform 0.2s;
    }

    &::-ms-thumb {
      // disables default browser styling
      appearance: none;

      height: ${THUMB_DIAMETER};
      width: ${THUMB_DIAMETER};

      margin-top: -0.75em;

      border: none;

      border-radius: 50%;
      outline: none;

      background: ${(props) => props.theme.colors.secondaryIfDark};

      cursor: grab;

      // Use scale3d to prevent weird 1px jump at end
      transform: scale3d(1, 1, 1);
      transform-origin: center;
      transition: transform 0.2s;
    }
  }
`;

export const Slider = ({
  onChange,
  ...props
}: Omit<React.ComponentProps<typeof BaseSlider>, "percent">) => {
  const min = Number(props.min || 0);
  const max = Number(props.max || 1);

  const [value, setValue] = useState(
    is.number(props.value) ? props.value : (min + max) / 2,
  );

  useEffect(() => {
    if (is.number(props.value)) {
      setValue(props.value);
    }
  }, [props.value]);

  // eslint-disable-next-line
  const handleChange = React.useCallback(
    throttle((e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target) {
        return;
      }
      setValue(Number(e.target.value));

      if (onChange) {
        onChange(e);
      }
    }, 1000 / 30),
    [onChange],
  );

  const usedValue = typeof props.value === "number" ? props.value : value;

  const percent = ((usedValue - min) / (max - min)) * 100;

  return (
    <BaseSlider onChange={handleChange} percent={`${percent}%`} {...props} />
  );
};

Slider.defaultProps = { type: "range" };
