import { config } from "@react-spring/web";
import { NavLink } from "react-router-dom";

import { AnimatedNumber } from "../../../components";
import { toOrdinal } from "../../../shared";
import {
  StatCard,
  StatCardTitle,
  StatCardNumberText,
  StatCardProps,
} from "./StatCard";

type RankCardProps = Omit<StatCardProps, "children"> & {
  rank: number | null;
  gameId: string;
};

export const RankCard = ({ rank, gameId, ...props }: RankCardProps) => {
  return (
    <StatCard
      {...props}
      as={(propsInner) => (
        <NavLink
          {...propsInner}
          to={`/games/${gameId}/leaderboard`}
          style={{ ...propsInner.style, textDecoration: "none" }}
        />
      )}
    >
      <StatCardTitle>Rank</StatCardTitle>
      <StatCardNumberText>
        {typeof rank === "number" ? (
          <AnimatedNumber
            config={config.slow}
            initial={rank + 5}
            value={rank}
            transform={(value) => toOrdinal(Math.round(value))}
          />
        ) : (
          "—"
        )}
      </StatCardNumberText>
    </StatCard>
  );
};
