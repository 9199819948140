import { Component, ReactElement } from "react";

import { FullScreenErrorMessage } from "../../components";

type ErrorBoundaryScreenState = {
  hasError: boolean;
};

export class ErrorBoundaryScreen extends Component<
  { children?: ReactElement | ReactElement[] },
  ErrorBoundaryScreenState
> {
  public state = { hasError: false };

  public static getDerivedStateFromError(): ErrorBoundaryScreenState {
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return <FullScreenErrorMessage />;
    }

    return this.props.children;
  }
}
