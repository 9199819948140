const SUFFIXES = {
  one: "st",
  two: "nd",
  few: "rd",
  many: "th",
  zero: "th",
  other: "th",
};

/**
 * Formats a number as an ordinal.
 *
 * If Intl.PluralRules is available, it is used. Otherwise, American
 * English ordinal rules are applied.
 *
 * @example
 *
 * ```ts
 * toOrdinal(1);  // "1st"
 * toOrdinal(10); // "10th"
 * toOrdinal(11); // "11th"
 * ```
 */
export function toOrdinal(number: number) {
  const suffix = formatter
    ? SUFFIXES[formatter.select(number)]
    : getFallbackOrdinal(number);

  return `${number.toLocaleString()}${suffix}`;
}

const formatter =
  window.Intl && Intl.PluralRules
    ? new Intl.PluralRules(undefined, { type: "ordinal" })
    : null;

function getFallbackOrdinal(number: number) {
  if (number % 10 === 1 && number !== 11) return SUFFIXES.one;
  if (number % 10 === 2 && number !== 12) return SUFFIXES.two;
  if (number % 10 === 3 && number !== 13) return SUFFIXES.few;

  return SUFFIXES.other;
}
