import React, { ReactElement } from "react";
import { QueryClient, QueryClientProvider, QueryStatus } from "react-query";

export const QUERY_STATUSES: Record<
  "Error" | "Idle" | "Loading" | "Success",
  QueryStatus
> = {
  Error: "error",
  Idle: "idle",
  Loading: "loading",
  Success: "success",
};

export const queryClient = new QueryClient();

export const ReactQueryProvider: React.FunctionComponent<{
  children: ReactElement;
}> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
