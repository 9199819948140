import { ComponentProps, useMemo } from "react";
import {
  Image as BaseImage,
  ImageProps as BaseImageProps,
} from "@sidelinesports/rebass";

type IntrinsicDimensions = {
  intrinsicHeight?: number;
  intrinsicWidth?: number;
};

export type ImageProps = Omit<BaseImageProps, "css"> &
  IntrinsicDimensions & {
    lazy?: boolean;
  };

export const Image = ({
  alt,
  lazy = false,
  intrinsicHeight,
  intrinsicWidth,
  sx,
  ...props
}: ImageProps) => {
  const As = useMemo(() => {
    return (props: ComponentProps<"img">) => (
      <img
        alt=""
        height={intrinsicHeight}
        width={intrinsicWidth}
        loading={lazy ? "lazy" : "eager"}
        {...props}
      />
    );
  }, [intrinsicHeight, intrinsicWidth, lazy]);

  // rebass Image doesn't support the `loading` property, and you need to
  // memoize if you create the component inline, so I'm just using separately
  // defined components for lazy and eager loading
  return (
    <BaseImage
      alt={alt}
      as={As}
      sx={{ objectFit: "contain", objectPosition: "center", ...sx }}
      {...props}
    />
  );
};
