import { Card, CardProps } from "./Card";

export type PrimaryGradientCardProps = CardProps;

/**
 * A card with a gradient background, going from the primary color to the 10%
 * lighter variant of the primary color.
 */
export const PrimaryGradientCard = ({
  sx,
  ...props
}: PrimaryGradientCardProps) => (
  <Card
    border={false}
    shadow={true}
    sx={{
      background: (theme) => {
        return `linear-gradient(
          270deg,
          ${theme.colors.primary} 0%,
          ${theme.colors.primaryLight} 100%
        )`;
      },
      color: "primaryReadable",
      ...sx,
    }}
    {...props}
  />
);
