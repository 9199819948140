import { forwardRef } from "react";
import { Box, BoxProps } from "@sidelinesports/rebass";

export type ListItemProps = Omit<BoxProps, "css">;

export const ListItem = forwardRef(({ sx, ...props }: ListItemProps, ref) => (
  <Box
    ref={ref}
    bg="white"
    sx={{
      border: "1px solid #F0F0F0",
      borderRadius: "8px",
      boxShadow: "0 8px 16px -8px rgba(0,0,0,0.08)",
      ...sx,
    }}
    {...props}
  />
));
