import { Flex, Box } from "@sidelinesports/rebass";

import { ApiGameDetails, ApiRank } from "../../../api";
import { MenuButton } from "../../../components";
import { GameMenu } from "../components";

import { ViewResultButton } from "./ViewResultButton";
import { InProgressPill } from "./InProgressPill";

export const PageHeaderActions = ({
  game,
  ranking,
}: {
  game: ApiGameDetails;
  ranking: ApiRank;
}) => (
  <Flex flexDirection="row" alignItems="center">
    <Box pr="6px">
      {game.status === "in_progress" && <InProgressPill />}
      {game.status === "finished" && ranking && ranking.rank !== null && (
        <ViewResultButton game={game} position={ranking.rank} />
      )}
    </Box>
    <MenuButton Menu={(props) => <GameMenu game={game} {...props} />} />
  </Flex>
);
