import { ApiGameDetails } from "../../api";

type GameShortTitle = {
  abbreviation?: string;
  title: string;
};

/**
 * Gets a short title for a game.
 *
 * In the future, this may be sport-specific.
 *
 * @example
 *
 * ```ts
 * getShortTitle(awayGame); // { abbreviation: '＠MIL', title: "{Team} at Milwaukee Brewers" }
 * getShortTitle(neutralGame); // { abbreviation: 'VS MIL', title: "{Team} at Milwaukee Brewers" }
 * getShortTitle(homeGame); // { abbreviation: 'VS MIL', title: "Milwaukee Brewers at {Team}" }
 * getShortType(eventGame); // { title: 'Event Name String' }
 * ```
 */
type ApiGameDetailsForTitle = Pick<
  ApiGameDetails,
  "team" | "typeMatchup" | "typeEvent"
>;

export function getShortTitle(game: ApiGameDetailsForTitle): GameShortTitle {
  if (game.typeMatchup) {
    return {
      title: getMatchupShortTitle(game.team, game.typeMatchup),
      abbreviation: getMatchupAbbreviation(game.typeMatchup),
    };
  }

  if (game.typeEvent) {
    return { title: getEventShortTitle(game.typeEvent) };
  }

  throw new TypeError("Missing event or matchup details");
}

function getMatchupAbbreviation(
  matchup: NonNullable<ApiGameDetailsForTitle["typeMatchup"]>,
) {
  const isAway = matchup.where === "away";

  const opponentAbbreviation = matchup.opponent.abbreviation;

  return isAway ? `@${opponentAbbreviation}` : `VS ${opponentAbbreviation}`;
}

function getMatchupShortTitle(
  team: ApiGameDetailsForTitle["team"],
  matchup: NonNullable<ApiGameDetailsForTitle["typeMatchup"]>,
) {
  const isAway = matchup.where === "away";

  const teamName = team.name;
  const opponentName = matchup.opponent.name;

  return isAway
    ? `${teamName} at ${opponentName}`
    : `${opponentName} at ${teamName}`;
}

function getEventShortTitle(
  event: NonNullable<ApiGameDetailsForTitle["typeEvent"]>,
): string {
  return event.name;
}
