import negate from "lodash/negate";
import { isPlayerId } from "./filter-by-player";
import { isCreatedAfter } from "./filter-by-date";

type ScoringEvent = {
  playerId: string;
  gameEvent: {
    points: number;
    bonusPoints: number;
  };
  createdAt: string;
};

export type ScoringResult = {
  base: number;
  bonus: number;
  powerup: number;
  total: number;
};

export function getApplicableEventsWithPowerupPoints<T extends ScoringEvent>(
  picks: { playerId: string; powerupPoints: number }[],
  enteredAt: Date,
  events: T[],
) {
  return events
    .filter((event) =>
      picks.some(({ playerId }) => isPlayerId(playerId)(event)),
    )
    .map((event) => ({
      ...event,
      validForParticipant: isCreatedAfter(enteredAt)(event),
    }))
    .map((event) => ({
      ...event,
      powerupPoints: getPowerupPointsByPlayerId(picks)(event.playerId),
    }));
}

const getPowerupPointsByPlayerId =
  (picks: { playerId: string; powerupPoints: number }[]) =>
  (playerId: string) => {
    const matchingPick = picks.find((pick) => pick.playerId === playerId);
    if (!matchingPick) {
      console.error("Failed to find matching pick for playerId");
      return 0;
    } else {
      return matchingPick.powerupPoints;
    }
  };

export function getScoreByPlayerId(
  playerId: string,
  powerupPoints: number,
  enteredAt: Date,
) {
  return (events: ScoringEvent[]) => {
    const playerEvents = events.filter(isPlayerId(playerId));

    const applicableEvents = playerEvents.filter(isCreatedAfter(enteredAt));
    const missedEvents = playerEvents.filter(negate(isCreatedAfter(enteredAt)));

    return {
      playerId: playerId,

      applicable: getPointsForEvents(applicableEvents, {
        [playerId]: powerupPoints,
      }),
      missed: getPointsForEvents(missedEvents, {
        [playerId]: powerupPoints,
      }),
    };
  };
}

export function calculatePoints(
  events: ScoringEvent[],
  picks: { createdAt: string; playerId: string; powerupPoints: number }[],
) {
  if (picks.length > 0) {
    const enteredAt = new Date(picks[0].createdAt);
    const scores = getScoresByPicks(picks, enteredAt)(events);
    return scores.applicable.total;
  } else {
    return 0;
  }
}

export function getScoresByPicks(
  picks: { playerId: string; powerupPoints: number }[],
  enteredAt: Date,
) {
  return (events: ScoringEvent[]) => {
    return picks
      .map(({ playerId, powerupPoints }) =>
        getScoreByPlayerId(playerId, powerupPoints, enteredAt)(events),
      )
      .reduce(
        (previous, current) => ({
          applicable: {
            base: previous.applicable.base + current.applicable.base,
            bonus: previous.applicable.bonus + current.applicable.bonus,
            total: previous.applicable.total + current.applicable.total,
            powerup: previous.applicable.powerup + current.applicable.powerup,
          },
          missed: {
            base: previous.missed.base + current.missed.base,
            bonus: previous.missed.bonus + current.missed.bonus,
            total: previous.missed.total + current.missed.total,
            powerup: previous.missed.powerup + current.missed.powerup,
          },
        }),
        {
          applicable: { base: 0, bonus: 0, total: 0, powerup: 0 },
          missed: { base: 0, bonus: 0, total: 0, powerup: 0 },
        },
      );
  };
}

export function getPointsForEvents(
  events: ScoringEvent[],
  idToPowerupMap: Record<string, number>,
): ScoringResult {
  return events.reduce(
    (previous, event) => {
      const { points, bonusPoints } = event.gameEvent;
      const extra = idToPowerupMap[event.playerId] || 0;

      const next = {
        base: points,
        total: points + extra + bonusPoints,
        powerup: extra,
        bonus: bonusPoints,
      };
      return {
        base: previous.base + next.base,
        total: previous.total + next.total,
        powerup: previous.powerup + next.powerup,
        bonus: previous.bonus + next.bonus,
      };
    },
    { base: 0, bonus: 0, total: 0, powerup: 0 },
  );
}
