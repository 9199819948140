import { useQuery } from "react-query";

import { getGameById, getPlayers } from "../../api";

export function useGameQuery(gameId: string) {
  return useQuery([gameId, "games"], () => getGameById(gameId));
}

export function usePlayersQuery() {
  return useQuery(["players"], () => getPlayers());
}
