import { Text, TextProps } from "@sidelinesports/rebass";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Pill = ({ sx, ...props }: Omit<TextProps, "css">) => (
  <Text
    backgroundColor="secondary"
    color="secondaryReadable"
    fontSize="12px"
    fontWeight="600"
    lineHeight="16px"
    py="2px"
    px="8px"
    sx={{
      borderRadius: "4px",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }}
    {...props}
  />
);
