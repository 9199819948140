import { ComponentType } from "react";
import { Button, ButtonProps } from "@sidelinesports/rebass";

export type IconButtonProps = Omit<ButtonProps, "aria-label" | "css"> & {
  "aria-label": string;
  Icon: ComponentType;
  spinOnHover?: boolean;
};

export const IconButton = ({
  Icon,
  spinOnHover = true,
  sx,
  ...props
}: IconButtonProps) => (
  <Button
    color="inherit"
    sx={{
      "&:disabled": {
        cursor: "not-allowed",
      },
      "&:active": {
        borderColor: "rgba(255, 255, 255, 0.25)",
        transform: "translate3d(0, 0.1em, 0)",
      },
      "&:focus, &:hover, &:active": {
        backgroundColor: "rgba(210, 210, 210, 0.20)",
        outline: "none",
        transform: spinOnHover ? "rotate(360deg)" : undefined,
      },
      backgroundColor: "transparent",
      border: "1px solid transparent",
      borderRadius: "999px",
      cursor: "pointer",
      display: "inline-flex",
      height: "calc(1em + 10px)",
      lineHeight: 1,
      transition: "250ms background-color, 250ms border-color, 250ms transform",
      verticalAlign: "-10%",
      width: "calc(1em + 10px)",
      willChange: "background-color, border-color, transform",
      ...sx,
    }}
    {...props}
  >
    <Icon />
  </Button>
);
