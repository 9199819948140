import { ReactElement, FunctionComponent, createContext } from "react";
import { ApiUploadedImage } from "../../api";

type AppDetailsContextType = {
  appName: string | null;
  splashImage: ApiUploadedImage | null;
  teamName: string | null;
  teamImage: ApiUploadedImage | null;
  timezone: string;
  footers: { order: number; text: string; hyperlink: string }[];
};

export const AppDetailsContext = createContext({} as AppDetailsContextType);

export const AppDetailsProvider: FunctionComponent<{
  values: AppDetailsContextType;
  children?: ReactElement | ReactElement[];
}> = ({ values, children }) => {
  return (
    <AppDetailsContext.Provider value={values}>
      {children}
    </AppDetailsContext.Provider>
  );
};
