import { useMemo } from "react";
import { AllowedRatioOrAuto, getVariantDimensions } from "./ratios";

type Source = {
  height: number;
  width: number;
  url: string;
};

type Dimension =
  | { height: number; width?: undefined; ratio: AllowedRatioOrAuto }
  | { height?: undefined; width: number; ratio: AllowedRatioOrAuto };

export type ResizeOptions = {
  fit?: "cover" | "contain" | "fill";
  position?: "entropy" | "attention";
};

const DEFAULT_IMAGE_VARIANT_WIDTHS = [200, 700, 1200, 2000];

export function useResponsiveVariants(
  originalImage: Source,
  {
    options,
    ratio,
    sizes: specifiedSizes,
  }: {
    options?: ResizeOptions;
    ratio: AllowedRatioOrAuto;
    sizes?: Dimension[];
  },
) {
  const sizes = useMemo(() => {
    if (specifiedSizes) return specifiedSizes;

    return DEFAULT_IMAGE_VARIANT_WIDTHS.map((width) => ({ width, ratio }));
  }, [specifiedSizes, ratio]);

  const sources = useMemo(
    () => constructVariantURLs(originalImage, sizes, options),
    [options, originalImage, sizes],
  );

  return sources;
}

function constructVariantURLs(
  image: Source,
  sizes: Dimension[],
  options: ResizeOptions = {},
) {
  return sizes
    .map((size) => ({
      ...(size.height && { height: size.height }),
      ...(size.width && { width: size.width }),
      ...(size.ratio !== "auto" && { ratio: size.ratio }),
      ...(options.fit && { fit: options.fit }),
      ...(options.position && { strategy: options.position }),
    }))
    .map((options) => {
      const url = new URL(image.url);

      for (const [key, value] of Object.entries(options)) {
        url.searchParams.set(key, value.toString());
      }

      return {
        url: url.toString(),
        ...getVariantDimensions(image, options),
      };
    });
}
