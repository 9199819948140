import { ComponentType } from "react";

import { Box, Heading } from "@sidelinesports/rebass";

import {
  ApiPick,
  ApiScoringEvent,
  ApiGameSponsor,
  ApiAvailableEvent,
} from "../../api";
import {
  SCREEN_GUTTER_X,
  SCREEN_GUTTER_TOP,
  SCREEN_GUTTER_BOTTOM,
} from "../../constants";
import { CancelIcon } from "../../assets";
import { FullScreenModal, IconButton, ModalProps } from "../../components";
import {
  getScoresByPicks,
  getApplicableEventsWithPowerupPoints,
} from "../../logic";

import { canShowPointBreakdown } from "./can-show-point-breakdown";
import { BreakdownChart, BreakdownRow } from "./components";

type PointBreakdownModalProps = {
  Modal?: ComponentType<ModalProps>;

  events: ApiScoringEvent[];
  picks: ApiPick[];
  availableEvents: ApiAvailableEvent[];
  sponsor?: ApiGameSponsor;
} & ModalProps;

export const PointBreakdownModal = ({
  Modal = FullScreenModal,
  events,
  picks,
  availableEvents,
  sponsor,
  ...props
}: PointBreakdownModalProps) => {
  if (!canShowPointBreakdown({ events, picks })) {
    return null;
  }

  const bonusEventsExist = availableEvents.some((x) => x.bonusPoints > 0);

  const enteredAt = new Date(picks[0]?.createdAt ?? undefined);
  const myPoints = getScoresByPicks(picks, enteredAt)(events);
  const filteredEventsWithPicks = getApplicableEventsWithPowerupPoints(
    picks,
    enteredAt,
    events,
  ).filter((event) => event.validForParticipant);

  return (
    <Modal {...props}>
      <Box
        px={SCREEN_GUTTER_X}
        pt={SCREEN_GUTTER_TOP}
        pb={SCREEN_GUTTER_BOTTOM}
        width="100%"
        height="100%"
      >
        <Box alignSelf="flex-end" ml="auto" mb="28px" width="100%">
          <IconButton
            aria-label="Close"
            style={{ marginLeft: "calc(100% - 24px)" }}
            Icon={CancelIcon}
            fontSize="30px"
            onClick={props.onRequestClose}
          />
        </Box>
        <Box flex="1 0 auto" width="100%">
          <Heading mb="24px" variant="h100">
            Point Breakdown
          </Heading>
          <BreakdownChart
            mb="24px"
            bonusPointsSetUp={bonusEventsExist}
            sponsor={sponsor}
            points={myPoints.applicable}
          />
          <Box>
            {filteredEventsWithPicks.map((event) => (
              <BreakdownRow key={event.id} event={event} sponsor={sponsor} />
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
