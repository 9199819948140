import { getClient } from "./client";
import {
  ApiLeader,
  ApiScoringEvent,
  ApiScoringEventResponse,
  ApiRank,
} from "./shared";

export type ApiScoringAndRankInfo = {
  events: ApiScoringEvent[];
  rank: ApiRank;
  leaders: ApiLeader[];
};

/**
 * Aggregates requests from the following routes:
 *
 * 1. scoring events (public)
 * 2. leaderboard (public)
 * 3. fan rank (private)
 *
 * This ensures that info from these requests is always synchronized in the UI.
 */
export async function getScoringAndRank(
  gameId: string,
): Promise<ApiScoringAndRankInfo> {
  const { events, lastUpdatedAt } = await getScoringEventsByGameId(gameId);

  const version = lastUpdatedAt ?? "";

  const [rank, leaders] = await Promise.all([
    getGameRank(gameId, version),
    getLeadersByGameId(gameId, version),
  ]);

  return { events, leaders, rank };
}

const getScoringEventsByGameId = (gameId: string) =>
  getClient()
    .get(`/api/games/${gameId}/scoring-events`)
    .json<ApiScoringEventResponse>();

const getGameRank = async (gameId: string, version: string) =>
  getClient({ authenticate: true })
    .get(`/api/games/${gameId}/rank`, { searchParams: { version } })
    .json<ApiRank>();

const getLeadersByGameId = async (gameId: string, version: string) =>
  getClient()
    .get(`/api/games/${gameId}/leaders`, { searchParams: { version } })
    .json<ApiLeader[]>();
