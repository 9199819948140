import { Heading, Text } from "@sidelinesports/rebass";
import { ROLLING_STONE } from "pick3-theme";

import { ApiGameDetails, ApiAvailableEventSponsored } from "../../api";
import { Divider } from "../../components";
import { EventsTable, SponsoredEventPanel } from "./components";

type ScoreLegendProps = {
  game: ApiGameDetails;
};

export const ScoreLegend = ({ game }: ScoreLegendProps) => {
  const sponsoredEvents = game.availableEvents.filter(
    (event): event is ApiAvailableEventSponsored => event.sponsored,
  );

  if (sponsoredEvents.length && !game.gameSponsor) {
    console.warn(
      "Should be impossible to have sponsored events without game sponsor",
    );
    return null;
  }

  return (
    <>
      <Heading mb="20px" variant="h100">
        Score Legend
      </Heading>
      <Text color={ROLLING_STONE} mb="24px" variant="body">
        Listed below are events in which the players you select can score points
        from.
      </Text>
      {sponsoredEvents.length !== 0 && (
        <>
          <SponsoredEventPanel
            sponsor={game.gameSponsor!}
            events={sponsoredEvents}
          />
          <Divider my="23px" />
        </>
      )}
      <EventsTable events={game.availableEvents} />
    </>
  );
};
