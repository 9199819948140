import { createContext, useContext, useMemo } from "react";
import { useActivityTracking } from "./use-activity-tracking";

export type TrackingProviderState = {
  track: (event: string, properties?: Record<string, unknown>) => void;
};

export type TrackingProviderProps = TrackingProviderState;

const TrackingContext = createContext<TrackingProviderState>({
  track() {
    throw new Error("TrackingProvider not initialized");
  },
});

export const TrackingProvider = ({
  track,
  children,
}: React.PropsWithChildren<TrackingProviderProps>) => {
  const state = useMemo(() => ({ track }), [track]);
  useActivityTracking(track);

  return (
    <TrackingContext.Provider value={state}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  return useContext(TrackingContext).track;
};
