import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  FullScreenErrorMessage,
  FullScreenLoadingAnimation,
} from "./components";

const AvailableGamesPage = lazy(() => import("./screens/available-games"));
const DemoPage = lazy(() => import("./screens/demo"));
const LaunchPage = lazy(() => import("./screens/launch"));
const OnboardingPage = lazy(() => import("./screens/onboarding"));
const GameHistoryPage = lazy(() => import("./screens/game-history"));
const SSOPage = lazy(() => import("./screens/sso"));
const TutorialReviewPage = lazy(
  () => import("./screens/onboarding/TutorialReviewPage"),
);

const GameRouter = lazy(() => import("./GameRouter"));

export const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<FullScreenLoadingAnimation />}>
        <RoutesInner />
      </Suspense>
    </BrowserRouter>
  );
};

const RoutesInner = () => (
  <Routes>
    <Route path="/" element={<LaunchPage />} />
    <Route path="/oops" element={<FullScreenErrorMessage refreshTo="/" />} />
    <Route path="/available-games" element={<AvailableGamesPage />} />
    <Route path="/my-game-history" element={<GameHistoryPage />} />
    <Route path="/demo/:tenant" element={<DemoPage />} />
    <Route path="/games/*" element={<GameRouter />} />
    <Route path="/sso/:tenant" element={<SSOPage />} />
    <Route path="/tutorial" element={<TutorialReviewPage />} />
    <Route path="/welcome" element={<OnboardingPage />} />
    <Route path="*" element={<FullScreenErrorMessage />} />
  </Routes>
);
