import styled from "@emotion/styled";
import { Modal } from "./Modal";

export const FullScreenModal = styled(Modal)`
  background: ${({ background = "white" }) => background};
  height: 100%;
  overflow: auto;

  outline: none;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: ${({ justifyContent = "center" }) => justifyContent};

  -webkit-overflow-scrolling: touch;
`;

FullScreenModal.defaultProps = {
  style: {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.64)",
      zIndex: 200,
    },
  },
};
