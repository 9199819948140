import { useEffect, useMemo, useRef } from "react";

export function useFPS() {
  const historyRef = useRef<number[]>([]);

  const getFPS = useMemo(
    () =>
      function getAverageFPS() {
        return historyRef.current.length;
      },
    [],
  );

  const recordFrame = useMemo(
    () =>
      function recordFrame() {
        const now = (performance || Date).now();
        const history = historyRef.current;

        while (history.length > 0 && history[0] < now - 1000) {
          history.shift();
        }

        history.push(now);
      },
    [],
  );

  useRAF(recordFrame);

  return getFPS;
}

function useRAF(callback: (delta: number) => void) {
  const requestRef = useRef<number | undefined>();
  const previousTimeRef = useRef<number | undefined>();

  const animate = useMemo(
    () => (time: number) => {
      if (typeof previousTimeRef.current !== "undefined") {
        const delta = time - previousTimeRef.current;
        callback(delta);
      }

      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback],
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
  }, [animate]);
}
