import { Link, LinkProps } from "@sidelinesports/rebass";

export const ExternalLink = ({
  children,
  target: initialTarget,
  ...props
}: Omit<LinkProps, "css">) => {
  const { href } = props;
  const isHTTP = href?.startsWith("http");

  const target = isHTTP ? "_blank" : initialTarget;

  // not required in modern browsers (_blank implies noopener), but satisfies
  // Lighthouse check
  const rel = target === "_blank" ? "noopener" : "opener";

  return (
    <Link rel={rel} target={target} {...props}>
      {children}
    </Link>
  );
};
