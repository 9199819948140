import { Flex, Image, Text, TextProps } from "@sidelinesports/rebass";
import { ROLLING_STONE } from "pick3-theme";
import { ApiGameSponsor } from "../../api";

type SponsorFooterProps = {
  sponsor: ApiGameSponsor;
};

export const SponsorFooter = ({ sponsor }: SponsorFooterProps) => (
  <Flex as="footer" alignItems="center" justifyContent="center">
    <FooterText>Presented by</FooterText>
    <Image height="16px" pl="2px" src={sponsor.image.url} />
  </Flex>
);

const FooterText = (props: Omit<TextProps, "css">) => (
  <Text
    color={ROLLING_STONE}
    textAlign="center"
    variant="h600"
    style={{ whiteSpace: "nowrap" }}
    {...props}
  />
);
