import {
  Card,
  CardProps,
  Text,
  Flex,
  TextProps,
  FlexProps,
} from "@sidelinesports/rebass";

export type StatCardProps = Omit<CardProps, "css">;

/**
 * Displays a numeric statistic with a title
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StatCard = ({ children, sx, title, ...props }: StatCardProps) => (
  <Card
    backgroundColor="rgba(0, 0, 0, 0.16)"
    color="secondary"
    p="16px"
    sx={{ border: "none", borderRadius: "8px", ...sx }}
    {...props}
  >
    {children}
  </Card>
);

export const StatCardTitle = ({ sx, ...props }: Omit<TextProps, "css">) => (
  <Text
    fontSize="11px"
    lineHeight="14px"
    color="white"
    mb="2px"
    sx={{ textTransform: "uppercase", ...sx }}
    {...props}
  />
);

export const StatCardNumberText = ({
  sx,
  ...props
}: Omit<FlexProps, "css">) => (
  <Flex
    fontSize="24px"
    fontWeight="bold"
    lineHeight="28px"
    flexDirection="row"
    alignItems="center"
    color="white"
    sx={sx}
    {...props}
  />
);
