import { animated, useSpring, SpringConfig } from "@react-spring/web";

export const AnimatedNumber = ({
  config,
  initial = 0,
  transform = toLocaleInteger,
  value,
}: {
  config?: SpringConfig;
  initial?: number;
  transform?: (value: number) => number | string;
  value: number;
}) => {
  const props = useSpring({ config, number: value, from: { number: initial } });
  return <animated.span>{props.number.to(transform)}</animated.span>;
};

/**
 * Transforms a number to locale number format, rounding to the nearest integer.
 */
function toLocaleInteger(number: number) {
  return formatter.format(number);
}

const isIntlSupported = Boolean(window.Intl);

const formatter = isIntlSupported
  ? new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 0,
    })
  : { format: (number: number) => Math.round(number).toString() };
