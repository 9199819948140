import {
  Box,
  Heading,
  Button,
  ButtonProps,
  Image,
} from "@sidelinesports/rebass";

import { MarkdownSmaller } from "../../components";
import { ApiEndMessage, ApiGameDetails } from "../../api";
import { FinishConfetti } from "./FinishConfetti";
import { PositionTitle } from "./PositionTitle";
import { TrophyVector } from "../../assets";
import { PrizeCode } from "./CodeBox";
import { SponsorFooter } from "./SponsorFooter";

type Mandate<T, K extends keyof T> = T & {
  [Key in K]-?: NonNullable<T[Key]>;
};

type EndMessageWithCode = ApiEndMessage & {
  codeInfo: Mandate<ApiEndMessage, "codeInfo"> & {
    code: Mandate<NonNullable<ApiEndMessage["codeInfo"]>, "code">;
  };
};

export const GameFinishMessage = ({
  onClose,
  endMessage,
  game,
  position,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: any[]) => void;
  endMessage: ApiEndMessage;
  game: ApiGameDetails;
  position: number;
}) => {
  const TROPHY_NORMAL = 120;
  const TROPHY_SHORT = 193;
  const topImageHeight = hasPrizeCode(endMessage)
    ? TROPHY_NORMAL
    : TROPHY_SHORT;

  return (
    <>
      {shouldShowConfetti(position) && <FinishConfetti />}
      {endMessage.imageUrl ? (
        <Image src={endMessage.imageUrl} height={topImageHeight} />
      ) : endMessage.result === "win" ? (
        <TrophyVector height={topImageHeight} position={position} />
      ) : (
        <PositionTitle position={position} />
      )}
      {hasPrizeCode(endMessage) && (
        <PrizeCode info={endMessage.codeInfo} mb="16px" />
      )}
      <Heading mb="24px" variant="h100" textAlign="center">
        {endMessage.title}
      </Heading>
      <Box mb="24px">
        <MarkdownSmaller contents={endMessage.text} />
      </Box>
      <AcceptButton onClick={onClose}>Got it, thanks!</AcceptButton>
      {hasSponsor(game) && (
        <Box pt={"24px"}>
          <SponsorFooter sponsor={game.gameSponsor} />
        </Box>
      )}
      <Box flex="0 1 48px" />
    </>
  );
};

const hasPrizeCode = (message: ApiEndMessage): message is EndMessageWithCode =>
  message.codeInfo?.code != null;

const hasSponsor = (
  game: ApiGameDetails,
): game is Mandate<ApiGameDetails, "gameSponsor"> => game.gameSponsor != null;

const shouldShowConfetti = (position: number) => position <= 100;

const AcceptButton = (props: Omit<ButtonProps, "css">) => (
  <Button
    backgroundColor="transparent"
    color="inherit"
    fontSize="14px"
    fontWeight={600}
    minWidth="209px"
    px="24px"
    py="17px"
    onClick={props.onClick}
    sx={{
      border: "1px solid #B3B4B5",
      borderRadius: "28px",
      cursor: "pointer",
      letterSpacing: "1.2px",
      lineHeight: "17px",
      textTransform: "uppercase",
    }}
  >
    {props.children}
  </Button>
);
