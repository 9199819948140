import { NavLink } from "react-router-dom";
import { Flex, Link, LinkProps } from "@sidelinesports/rebass";
import { SHARK } from "pick3-theme";

export const Navigation = ({ gameId }: { gameId: string }) => {
  return (
    <Flex mt="8px">
      <StyledLink
        as={(props) => <NavLink to={`/games/${gameId}/play`} {...props} />}
        mr="24px"
      >
        Dashboard
      </StyledLink>
      <StyledLink
        as={(props) => (
          <NavLink to={`/games/${gameId}/leaderboard`} {...props} />
        )}
      >
        Leaderboard
      </StyledLink>
    </Flex>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledLink = ({ sx, ...props }: Omit<LinkProps, "css">) => (
  <Link
    color={SHARK}
    fontSize={["14px", "14px", "16px", "18px"]}
    pb="8px"
    sx={{
      "&.active": {
        borderBottomColor: "secondaryIfDark",
      },
      borderBottomColor: "transparent",
      borderBottomStyle: "solid",
      borderBottomWidth: "4px",
      fontWeight: 600,
      lineHeight: "1.25",
    }}
    {...props}
  />
);
