import { PropsWithChildren } from "react";
import { Text } from "@sidelinesports/rebass";

export const PlayerName = ({
  player: { firstName, lastName },
}: {
  player: {
    firstName: string;
    lastName: string;
  };
}) => (
  <>
    <FirstName>{firstName}</FirstName>
    <LastName>{lastName}</LastName>
  </>
);

const FirstName = (props: PropsWithChildren<unknown>) => (
  <Text
    color="white"
    fontSize="9px"
    lineHeight="12px"
    sx={{
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
    {...props}
  />
);

const LastName = (props: PropsWithChildren<unknown>) => (
  <Text
    color="white"
    fontSize="18px"
    fontWeight={700}
    lineHeight="20px"
    sx={{
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
    {...props}
  />
);
