import { getClient } from "./client";

export type ApiPick = {
  id: string;
  gameId: string;
  powerupPoints: number;
  playerId: string;
  createdAt: string;
};

export const getPicksByGameId = (gameId: string) =>
  getClient({ authenticate: true })
    .get(`/api/games/${gameId}/picks`)
    .json<ApiPick[]>();

export const postPicksByGameId = (
  gameId: string,
  data: { picks: { playerId: string; tokens: number }[] },
) =>
  getClient({ authenticate: true })
    .post(`/api/games/${gameId}/picks`, { json: data })
    .json();

export const putPicksByGameId = (
  gameId: string,
  data: { picks: { playerId: string; tokens: number }[] },
) =>
  getClient({ authenticate: true })
    .put(`/api/games/${gameId}/picks`, { json: data })
    .json();
