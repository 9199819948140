import {
  createContext,
  PropsWithChildren,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import uniqueId from "lodash/uniqueId";
import ms from "ms";

type ToastVariant = "success" | "error" | "default";

interface IToast {
  id: string;
  variant: ToastVariant;

  duration: number;
  message: string;
  title?: string;
}

interface IToastRequest {
  variant?: IToast["variant"];

  duration?: IToast["duration"];
  message: IToast["message"];
  title?: IToast["title"];
}

type CurrentToastState = {
  toast: IToast | undefined;
  remove(): void;
};

export const CurrentToastContext = createContext<CurrentToastState>({
  toast: undefined,
  remove() {
    console.warn("Not called from within a defined CurrentToastContext");
  },
});

type AddToastState = {
  add(toast: IToastRequest): void;
};

export const AddToastContext = createContext<AddToastState>({
  add() {
    console.warn("Not called from within a defined AddToastContext");
  },
});

export const ToastsProvider = ({
  children,
  initial = [],
}: PropsWithChildren<{ initial?: IToast[] }>) => {
  const [toasts, setToasts] = useState(initial);

  /**
   * Adds a new toast at the end
   */
  const addToast = useCallback(
    (toast: IToastRequest) => {
      setToasts((current) => [...current, withDefaults(toast)]);
    },
    [setToasts],
  );

  /**
   * Removes the currently visible toast (if there is one)
   */
  const removeCurrentToast = useCallback(() => {
    setToasts((current) => current.slice(1));
  }, [setToasts]);

  const currentToastState = useMemo(
    () => ({
      toast: toasts[0],
      remove: removeCurrentToast,
    }),
    [toasts, removeCurrentToast],
  );

  const addToastState = useMemo(() => ({ add: addToast }), [addToast]);

  return (
    <CurrentToastContext.Provider value={currentToastState}>
      <AddToastContext.Provider value={addToastState}>
        {children}
      </AddToastContext.Provider>
    </CurrentToastContext.Provider>
  );
};

export function useToast() {
  return useContext(AddToastContext).add;
}

export function useToaster(message: string, variant: ToastVariant = "default") {
  const toast = useToast();

  return useCallback(
    () => toast({ message, variant }),
    [message, toast, variant],
  );
}

const DEFAULTS: Record<ToastVariant, Pick<IToast, "title" | "duration">> = {
  default: {
    title: undefined,
    duration: ms("5s"),
  },
  error: {
    title: "Oh no!",
    duration: 0,
  },
  success: {
    title: "Success",
    duration: ms("5s"),
  },
};

function withDefaults(request: IToastRequest): IToast {
  const { variant = "default" } = request;

  return {
    id: uniqueId("toast_"),
    variant,

    ...DEFAULTS[variant],
    ...request,
  };
}
