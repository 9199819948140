const when = <T>(options: { dev?: T }, fallback: T) => {
  if (import.meta.env.NODE_ENV === "development") {
    return options.dev ?? fallback;
  }

  return fallback;
};

const SETTINGS = {
  "queries.polling.short": when({ dev: 2000 }, 5000),
  "queries.polling.medium": when({ dev: 5000 }, 10000),
  "queries.polling.long": when({ dev: 7000 }, 30000),
  "queries.polling.very-long": when({ dev: 15000 }, 60000),
};

export default SETTINGS;
export type AppSettings = typeof SETTINGS;

/**
 * Gets an application-level setting.
 *
 * Currently these are hardcoded in as constants, but they will be dynamic at
 * runtime in the future.
 */
export function useSetting<Name extends keyof AppSettings>(name: Name) {
  return SETTINGS[name] as AppSettings[Name];
}
