import { memo, useMemo } from "react";

import { Flex, Box } from "@sidelinesports/rebass";

import {
  ApiGameDetails,
  ApiRank,
  ApiPick,
  ApiScoringEvent,
  ApiPlayerDetails,
} from "../../../api";
import { PrimaryGradientHeader, ContentArea } from "../../../components";
import {
  isMissedGame,
  calculatePoints,
  getScoreByPlayerId,
} from "../../../logic";

import { PointsCard } from "./PointsCard";
import { RankCard } from "./RankCard";
import { MissedCard } from "./MissedCard";

import { Navigation } from "./Navigation";
import { PageHeaderGameInfo } from "./PageHeaderGameInfo";
import { PageHeaderActions } from "./PageHeaderActions";
import { Players } from "./player-section";
import { SCREEN_GUTTER_X } from "../../../constants";

type PageHeaderProps = {
  game: ApiGameDetails;
  players: ApiPlayerDetails[];
  ranking: ApiRank;
  picks: ApiPick[];
  events: ApiScoringEvent[];
};

const UnmemoizedPageHeader = ({
  game,
  players,
  ranking,
  picks,
  events,
}: PageHeaderProps) => {
  const missedGame = isMissedGame(game, picks);
  const points = calculatePoints(events, picks);

  const myPlayers = useMemo(
    () =>
      picks
        .map((pick) => {
          const player = players.find((player) => player.id === pick.playerId)!;

          if (!player) {
            return null;
          }

          return {
            ...player,
            powerupPoints: pick.powerupPoints,
          };
        })
        .filter(
          (player): player is ApiPlayerDetails & { powerupPoints: number } =>
            player !== null,
        ),
    [players, picks],
  );

  const enteredAt: string | undefined = picks[0]?.createdAt;

  const pointsByPlayer = picks.map(({ playerId, powerupPoints }) =>
    getScoreByPlayerId(playerId, powerupPoints, new Date(enteredAt))(events),
  );

  return (
    <>
      <PrimaryGradientHeader
        pt="12px"
        px="0px"
        height={missedGame ? "200px" : "450px"}
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          py="12px"
          px={SCREEN_GUTTER_X}
        >
          <Box pt="6px" mb={8}>
            <PageHeaderGameInfo game={game} />
          </Box>
          <Box>
            <PageHeaderActions game={game} ranking={ranking} />
          </Box>
        </Flex>
        <Flex px={SCREEN_GUTTER_X}>
          {!missedGame ? (
            <Flex mx="-2px" width="100%">
              <RankCard
                flex={1}
                rank={ranking.rank}
                gameId={game.id}
                mr="8px"
              />
              <PointsCard gameId={game.id} flex={1} points={points} />
            </Flex>
          ) : (
            <MissedCard />
          )}
        </Flex>
        {!missedGame && (
          <ContentArea
            maxWidth={["40em", "40em", "42em"]}
            mt={"16px"}
            px={0}
            sx={{ overflowX: "hidden" }}
          >
            <Players game={game} players={myPlayers} points={pointsByPlayer} />
          </ContentArea>
        )}
      </PrimaryGradientHeader>
      <Box px={SCREEN_GUTTER_X} mt={missedGame ? "25px" : "85px"} pb="16px">
        <Navigation gameId={game.id} />
      </Box>
    </>
  );
};

export const PageHeader = memo(UnmemoizedPageHeader);
