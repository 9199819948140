import { useEffect } from "react";
import Modal from "react-modal";

import {
  AnimationController,
  ToastsProvider,
  Toasts,
  ReactQueryProvider,
} from "./components";
import { AppLayout } from "./layouts";
import { Router } from "./Router";
import { ErrorBoundaryScreen } from "./screens/error-screen";
import { TrackingProvider } from "./analytics";

declare global {
  interface Window {
    __track: (event: string, properties?: Record<string, unknown>) => void;
  }
}

function App() {
  useEffect(function hideAppLoadingScreen() {
    document.getElementById("app-loading-screen")?.classList.add("loaded");
  }, []);

  return (
    <ErrorBoundaryScreen>
      <TrackingProvider
        track={(event, properties) => {
          if (window.__track) {
            window.__track(event, properties);
          }

          if (import.meta.env.NODE_ENV === "development") {
            console.debug("track", event, properties);
          }
        }}
      >
        <ToastsProvider>
          <AnimationController />
          <Toasts />
          <ReactQueryProvider>
            <AppLayout>
              <Router />
            </AppLayout>
          </ReactQueryProvider>
        </ToastsProvider>
      </TrackingProvider>
    </ErrorBoundaryScreen>
  );
}

Modal.setAppElement("#root");

export default App;
