import {
  ReactChild,
  ReactElement,
  ComponentType,
  FunctionComponent,
} from "react";

import { Box, BoxProps } from "@sidelinesports/rebass";

import { Card } from "../cards";
import {
  HeadshotBackground,
  PLAYER_HEADSHOT_RESIZE_RATIO,
} from "./HeadshotBackground";
import { PlayerName } from "./PlayerName";
import { CardOverlay } from "./CardOverlay";
import { ApiPlayerDetails } from "../../api";
import { AspectRatioBox } from "../AspectRatioBox";
import { ratioToFloat } from "../Image";

export type PlayerCardProps = {
  player: Pick<
    ApiPlayerDetails,
    "id" | "firstName" | "lastName" | "number" | "position" | "headshot"
  >;

  /** additional element displayed at the top of the card */
  top?: ReactChild;

  /** additional element displayed in the middle of the card (above name) */
  middle?: ReactChild;

  /** additional element displayed below the player name */
  bottom?: ReactChild;

  /** shading overlay */
  overlay?: ReactChild;

  /** whether to load the background lazily or eagerly */
  lazy?: boolean;

  /** outermost container */
  Container?: ComponentType;
} & Omit<BoxProps, "children" | "css">;

export const PlayerCard = ({
  player,

  top,
  middle,
  bottom,

  overlay = <CardOverlay />,
  lazy = false,

  ...props
}: PlayerCardProps) => (
  <PlayerCardBox {...props}>
    <HeadshotBackground lazy={lazy} player={player} widths={["50vw", "33vw"]} />
    {overlay}
    <Box flex="0 0 auto">{top}</Box>
    <Box display="flex" flex="1 0 auto">
      {middle}
    </Box>
    <Box
      id={`player-name-${player.id}`}
      flex="0 0 auto"
      sx={{ position: "relative" }}
    >
      <PlayerName player={player} />
    </Box>
    <Box flex="0 0 auto">{bottom}</Box>
  </PlayerCardBox>
);

export const PlayerCardBox = ({
  Container = AspectRatioContainer,
  children,
  sx,
  ...props
}: Omit<BoxProps, "css"> & {
  Container?: ComponentType<{ children?: ReactElement }>;
}) => (
  <Container>
    <Card
      border={true}
      display="flex"
      p={[3, 4]}
      sx={{
        borderWidth: "4px",
        flexDirection: "column",
        // overflow: hidden prevents the player image from clipping the border
        overflow: "hidden",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Card>
  </Container>
);

const AspectRatioContainer: FunctionComponent = (props) => (
  <AspectRatioBox
    aspectRatio={ratioToFloat(PLAYER_HEADSHOT_RESIZE_RATIO)}
    sx={{
      // the outer contianer has to have the box-shadow, or it will get clipped
      // by the card's overflow: hidden
      boxShadow: "0 8px 8px -8px rgba(0,0,0,0.08)",
    }}
    {...props}
  />
);
