import { Box, BoxProps } from "@sidelinesports/rebass";

export type AspectRatioBoxProps = Omit<BoxProps, "css"> & {
  aspectRatio: number | number[];
};

/**
 * https://css-tricks.com/aspect-ratio-boxes/
 */
export const AspectRatioBox = ({
  aspectRatio,
  sx,
  ...props
}: AspectRatioBoxProps) => (
  <Box
    height="0"
    overflow="hidden"
    paddingTop={(Array.isArray(aspectRatio) ? aspectRatio : [aspectRatio]).map(
      (ratio) => `${(1 / ratio) * 100}%`,
    )}
    sx={{
      position: "relative",
      "> *": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      },
      ...sx,
    }}
    {...props}
  />
);
