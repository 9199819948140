import { Card, Text } from "@sidelinesports/rebass";

export const MissedCard = () => (
  <Card
    backgroundColor="rgba(0, 0, 0, 0.16)"
    color="white"
    p="16px"
    sx={{ border: "none", borderRadius: "8px" }}
  >
    <Text fontSize="18px" fontWeight="bold" color="white" mb="4px">
      No Data Available
    </Text>
    <Text fontSize="12px" lineHeight="16px" color="white" opacity="0.7">
      Uh oh — you entered the game a little too late to play. Join us earlier
      next time!
    </Text>
  </Card>
);
