import {
  Button,
  Box,
  ButtonProps,
  Flex,
  Text,
  TextProps,
} from "@sidelinesports/rebass";

import { SHARK } from "pick3-theme";
import { CancelIcon } from "../../assets";

interface IToastProps {
  title?: string;
  message: string;
  onClose(): void;
}

export const Toast = ({ message, title, onClose }: IToastProps) => (
  <Flex
    flexDirection="row"
    backgroundColor={SHARK}
    color="white"
    width="100%"
    p={2}
  >
    <Box fontSize={1} p={3} width="90%">
      {title && <TitleText>{title}</TitleText>}
      <MessageText>{message}</MessageText>
    </Box>
    <Box>
      <CloseButton onClick={onClose} />
    </Box>
  </Flex>
);

const TitleText = (props: Omit<TextProps, "css">) => (
  <Text
    fontWeight={600}
    mb={2}
    style={{ display: "inline-block", textTransform: "uppercase" }}
    {...props}
  />
);

const MessageText = (props: Omit<TextProps, "css">) => (
  <Text flex="1" textAlign="left" variant="display" {...props} />
);

const CloseButton = (props: Omit<ButtonProps, "css">) => (
  <Button
    aria-label="Close Notification"
    style={{ backgroundColor: SHARK, color: "white", fontSize: "28px" }}
    onClick={props.onClick}
  >
    <CancelIcon />
  </Button>
);
