import { Box, BoxProps } from "@sidelinesports/rebass";

export const Divider = ({ sx, ...props }: Omit<BoxProps, "css">) => (
  <Box
    as="hr"
    color="#F0F0F0"
    sx={{ border: "none", borderBottom: "1px solid", ...sx }}
    width="100%"
    {...props}
  />
);
