import { Box, BoxProps, Heading } from "@sidelinesports/rebass";
import { toOrdinal } from "../../shared";

export const PositionTitle = ({ position }: { position: number }) => (
  <>
    <Heading fontSize="80px" lineHeight="88px" mb="4px" variant="title">
      {toOrdinal(position)}
    </Heading>
    <Underline mb="44px" />
  </>
);

const Underline = (props: Omit<BoxProps, "css">) => (
  <Box
    height="14px"
    width="200px"
    sx={{
      background: `radial-gradient(
        ellipse closest-side,
        rgba(224, 224, 224, 0.7) 0%,
        rgba(255, 255, 255, 0) 100%)`,
    }}
    {...props}
  />
);
