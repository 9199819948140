import {
  Card as BaseCard,
  CardProps as BaseCardProps,
} from "@sidelinesports/rebass";

export type CardProps = Omit<BaseCardProps, "css"> & {
  border?: boolean;
  shadow?: boolean;
};

export const Card = ({ border = true, shadow, sx, ...props }: CardProps) => (
  <BaseCard
    backgroundColor="white"
    sx={{
      borderColor: border ? "#F3F3F3" : undefined,
      borderRadius: "8px",
      borderStyle: border ? "solid" : undefined,
      borderWidth: border ? "1px" : "0",
      boxShadow: shadow ? "0 8px 8px -8px rgba(0,0,0,0.08)" : undefined,
      ...sx,
    }}
    {...props}
  />
);
