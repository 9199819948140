import { Flex, Heading, Text } from "@sidelinesports/rebass";

import { ApiAvailableEventSponsored, ApiGameSponsor } from "../../../api";
import { ResponsiveImage, Table } from "../../../components";

export type SponsoredEventPanelProps = {
  events: ApiAvailableEventSponsored[];
  sponsor: ApiGameSponsor;
};

export const SponsoredEventPanel = ({
  events,
  sponsor,
}: SponsoredEventPanelProps) => {
  return (
    <>
      <Flex alignItems="center" justifyContent="flex-start" mb="8px">
        <Heading variant="h400" pr={2} sx={{ flexShrink: "0" }}>
          Bonus by{" "}
        </Heading>
        {sponsor.image ? (
          <ResponsiveImage
            alt={`${sponsor.name} logo`}
            source={sponsor.image}
            sx={{ height: "16px", width: "auto" }}
          />
        ) : (
          <Text variant="tiny">{sponsor.name}</Text>
        )}
      </Flex>
      <Table>
        <tbody>
          {events.map((event, i) => (
            <Table.Row key={i}>
              <Table.Cell>{event.description}</Table.Cell>
              <Table.Cell
                color={"secondaryIfDark"}
                fontWeight={600}
                textAlign="right"
              >
                +{event.bonusPoints} Bonus
              </Table.Cell>
            </Table.Row>
          ))}
        </tbody>
      </Table>
    </>
  );
};
