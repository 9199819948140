import { SVGProps } from "react";
import { toOrdinal } from "../../shared";

const SvgTrophyVector = ({
  position,
  ...props
}: { position?: number } & SVGProps<SVGSVGElement>) => (
  <svg
    id="TrophyVector_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 500 500"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".TrophyVector_svg__st0{fill:#343536}.TrophyVector_svg__st1{fill:#f1f0f0}.TrophyVector_svg__st2{opacity:.1;enable-background:new}"
      }
    </style>
    <g id="TrophyVector_svg__Layer_3">
      <path
        className="TrophyVector_svg__st0"
        d="M140.3 355.8h217.4v99.4H140.3z"
      />
      <path className="TrophyVector_svg__st0" d="M113.9 455.1H384V473H113.9z" />
      <path className="TrophyVector_svg__st1" d="M224.9 264h52v91.8h-52z" />
      <path className="TrophyVector_svg__st2" d="M251 264h26v91.8h-26z" />
      <path fill="#fff" d="M173 382.8h151v55.8H173z" />
      <path
        className="TrophyVector_svg__st2"
        d="M277.1 276.9c-8.6 2.1-17.3 3.1-26 3.1s-17.6-1-26-3.1v14.6c8.6 2.1 17.3 3.1 26 3.1s17.6-1 26-3.1v-14.6z"
      />
      <path
        className="TrophyVector_svg__st1"
        d="M264.7 30.9H48.5v66.4c.1 48.1 36.7 88.3 84.6 92.8 19.2 52.7 64.7 90 117.4 90 52.8 0 98.3-37.3 117.4-90.1 47.3-5.3 83.2-45.1 83.3-92.8V30.9H264.7zM93 145.9c-13-12.8-20.4-30.4-20.3-48.7V55h50.6v79.4c0 10.1.9 20.1 2.7 30-12.4-2.8-23.8-9.3-33-18.5zm334-48.7c-.1 31.5-21.5 59.1-52.2 66.9 1.8-9.9 2.7-19.7 2.7-29.7V55H427v42.2z"
      />
      <path
        fill="var(--secondary-color)"
        d="M250.5 76.9l19.1 38.8 42.7 6.1-30.9 30.1 7.3 42.6-38.2-20.1-38.2 20.1 7.3-42.6-30.9-30.1 42.8-6.1z"
      />
      <path className="TrophyVector_svg__st1" d="M173 384h151v55.8H173z" />
      <path
        className="TrophyVector_svg__st2"
        d="M323.7 439.7h-73.2l-.1-55.7h73.3zM250.5 280c52.8 0 98.3-37.3 117.4-90.1 47.3-5.3 83.2-45.1 83.3-92.8V30.9H248.5m178.7 66.3c-.1 31.5-21.5 59.1-52.2 66.9 1.8-9.9 2.7-19.7 2.7-29.7V55h49.5v42.2z"
      />
      <path fill="none" d="M1 0h499.5v499.5H1z" />
    </g>
    {position && (
      <text
        transform="translate(210 426.272)"
        className="TrophyVector_svg__st0"
        fontSize={41.024}
        fontFamily="proxima-nova"
        fontWeight="bolder"
        x="42"
      >
        <tspan textAnchor="middle">{toOrdinal(position)}</tspan>
      </text>
    )}
  </svg>
);

export default SvgTrophyVector;
