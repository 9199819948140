import styled from "@emotion/styled";
import { Modal } from "./Modal";

export const LargeModal = styled(Modal)`
  background: ${({ background = "white" }) => background};
  overflow: auto;

  outline: none;
  margin: 40px 30px;
  max-width: 35em;

  @media (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }

  border-radius: 4px;
  box-shadow: 0 16px 24px -16px rgba(0, 0, 0, 0.4);

  padding: 54px 24px 42px 24px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: ${({ justifyContent = "center" }) => justifyContent};

  -webkit-overflow-scrolling: touch;
`;

LargeModal.defaultProps = {
  style: {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.64)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      zIndex: 200,
    },
  },
};
