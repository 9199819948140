import { Global } from "@emotion/react";
import { BODY_FONT, SHARK } from "pick3-theme";

export const GlobalCSS = ({
  primary,
  secondary,
}: {
  primary?: string;
  secondary?: string;
}) => (
  <Global
    styles={`
      * {
      box-sizing: border-box;
      }

      ${
        primary && secondary
          ? `
      :root {
        --primary-color: ${primary};
        --secondary-color: ${secondary};
      }
      `
          : ""
      }

      body, html, #root {
        height: 100%;
        width: 100%;

        margin: 0;
        box-sizing: border-box;

        user-select: none;
        font-family: ${BODY_FONT};

        // prefer geometricPrecision; fallback to optimizeLegibility
        text-rendering: optimizeLegibility;
        text-rendering: geometricPrecision;

        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
      }

      a, .selectable {
        user-select: auto;
      }

      body {
        background-color: #F0F0F0;
        color: ${SHARK};
      }
    `}
  />
);
