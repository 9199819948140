import { Overlay } from "../Overlay";

export const CardOverlay = () => (
  <Overlay
    sx={{
      background: `linear-gradient(
        180deg,
        transparent 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.7) 100%
      )`,
    }}
  />
);
