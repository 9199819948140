import { Box, BoxProps } from "@sidelinesports/rebass";

import { SCREEN_GUTTER_X } from "../constants";

/**
 * A top-level container.  Most app content should be placed inside a
 * ContentArea component.
 *
 * Each ContentArea component has:
 *
 * 1. gutter padding
 * 2. a max width, so content doesn't get too large on large screens
 * 3. automatic left and right margins, so the box is centered when width-bound
 */
export const ContentArea = ({ sx, ...props }: Omit<BoxProps, "css">) => (
  <Box
    mx="auto"
    maxWidth={["30em", "30em", "40em"]}
    px={SCREEN_GUTTER_X}
    sx={{ ...sx }}
    width="100%"
    {...props}
  />
);
