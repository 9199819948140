import {
  Flex,
  Box,
  BoxProps,
  Text,
  TextProps,
  Image,
} from "@sidelinesports/rebass";
import { ROLLING_STONE, SHARK } from "pick3-theme";
import { useTheme } from "@emotion/react";

import { LightningIcon } from "../../../assets";
import { ApiGameSponsor } from "../../../api";

export type BreakdownChartProps = {
  points: { base: number; bonus: number; total: number; powerup: number };
  sponsor?: ApiGameSponsor;
  bonusPointsSetUp: boolean;
} & Omit<BoxProps, "children" | "css">;

export const BreakdownChart = ({
  points,
  sponsor,
  bonusPointsSetUp,
  ...props
}: BreakdownChartProps) => {
  const theme = useTheme();
  return (
    <Box as="table" width="100%" {...props}>
      <thead>
        <tr>
          <HeaderCell>Event Pts</HeaderCell>
          <HeaderCell>Total Power-ups</HeaderCell>
          {sponsor && bonusPointsSetUp && (
            <HeaderCell>
              <Image
                src={sponsor.image.url}
                style={{
                  height: "14px",
                  objectFit: "contain",
                  verticalAlign: "middle",
                  maxWidth: "50px",
                }}
              />{" "}
              Bonus
            </HeaderCell>
          )}
          <HeaderCell textAlign="right">Total Pts</HeaderCell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Cell>{points.base.toLocaleString()}</Cell>
          <Cell>
            <Flex flexDirection="row" alignItems="center">
              <Box pr={2} mt={1}>
                <LightningIcon
                  color={theme.colors.secondaryIfDark}
                  height="20px"
                  width="20px"
                />
              </Box>
              {points.powerup.toLocaleString()}
            </Flex>
          </Cell>
          {sponsor && bonusPointsSetUp && (
            <Cell color={SHARK}>+{points.bonus.toLocaleString()}</Cell>
          )}
          <Cell textAlign="right">{points.total.toLocaleString()}</Cell>
        </tr>
      </tbody>
    </Box>
  );
};

const HeaderCell = (props: Omit<TextProps, "css">) => (
  <Text
    as="th"
    color={ROLLING_STONE}
    fontSize="12px"
    lineHeight="16px"
    fontWeight="normal"
    textAlign="left"
    mb="6px"
    {...props}
  />
);

const Cell = (props: Omit<TextProps, "css">) => (
  <Text as="td" variant="h200" {...props} />
);
