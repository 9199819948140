import { memo } from "react";
import { useMaybeDisableAnimations } from "../hooks";

/**
 * Enables and disables animations based on system performance and user
 * accessibility settings.
 *
 * By rendering this in a separate component, we prevent root-level renders.
 */
export const AnimationController = memo(() => {
  useMaybeDisableAnimations();

  return null;
});
