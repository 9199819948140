import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Image, Heading, Text, Button } from "@sidelinesports/rebass";
import { ROLLING_STONE, GALLERY } from "pick3-theme";
import { LoadErrorVector } from "../assets";
import { ContentArea } from "./ContentArea";

export const FullScreenErrorMessage = ({
  refreshTo,
  text = "Something has gone wrong. Please try again later.",
}: {
  refreshTo?: string;
  text?: string;
}) => {
  const nav = useNavigate();
  const refresh = useCallback(
    () => refreshTo && nav(refreshTo),
    [refreshTo, nav],
  );
  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      justifyContent="center"
      height="100%"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 10000,
      }}
    >
      <ContentArea sx={{ textAlign: "center" }}>
        <Image alt="Oh No!" as={LoadErrorVector} height="116px" mb="22px" />
        {/*
         * Specify typography rather than using variants in case this is rendered
         * outside a ThemeProvider context, e.g. with the root-level ErrorBoundary
         **/}
        <Heading
          fontFamily="proxima-nova"
          fontSize="24px"
          fontWeight={700}
          mb="8px"
        >
          Oh no!
        </Heading>
        <Text
          color={ROLLING_STONE}
          fontSize="14px"
          fontFamily="proxima-nova"
          lineHeight="20px"
          mb="24px"
          textAlign="center"
        >
          {text}
        </Text>
        {refreshTo !== undefined && (
          <Button
            sx={{
              border: `1px solid ${GALLERY}`,
              textTransform: "uppercase",
              borderRadius: "28px",
              paddingTop: "14px",
              paddingBottom: "14px",
              paddingLeft: "30px",
              paddingRight: "30px",
              background: "transparent",
              fontSize: "12px",
              fontFamily: "proxima-nova",
              color: ROLLING_STONE,
            }}
            onClick={refresh}
          >
            Refresh
          </Button>
        )}
      </ContentArea>
    </Flex>
  );
};
