import styled from "@emotion/styled";
import { Modal } from "./Modal";

export const SmallModal = styled(Modal)`
  background: ${({ background = "white" }) => background};
  overflow: auto;

  outline: none;

  margin-left: 7%;
  margin-right: 7%;

  border-radius: 16px;
  box-shadow: 0 16px 24px -16px rgba(0, 0, 0, 0.4);

  padding: 24px;

  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: ${({ justifyContent = "center" }) => justifyContent};

  -webkit-overflow-scrolling: touch;
`;

SmallModal.defaultProps = {
  style: {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.64)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      zIndex: 200,
    },
  },
};
