import { ComponentProps, useMemo } from "react";

import { ApiGameDetails } from "../../../api";
import { ScoreIcon, TrophyIcon, ListIcon } from "../../../assets";
import {
  AppMenu,
  MenuDivider,
  MenuItemLink,
  MenuItem,
} from "../../../components";
import { ScoreLegendModal } from "../../../screens/game-score-legend-modal/ScoreLegendModal";
import { ModalToggle } from "../../../screens/select-players/behaviors";
import { useLocation } from "react-router-dom";

type GameMenuProps = {
  game?: ApiGameDetails;
} & ComponentProps<typeof AppMenu>;

export const GameMenu = ({ game, ...props }: GameMenuProps) => {
  const loc = useLocation();
  return (
    <AppMenu {...props}>
      {game && (
        <>
          <MenuItemLink Icon={ListIcon} to={"/my-game-history"}>
            My Game History
          </MenuItemLink>
          {game.prizes.length > 0 && (
            <MenuItemLink
              Icon={TrophyIcon}
              to={`/games/${game.id}/prizing?${new URLSearchParams({
                redirectTo: loc.pathname,
              }).toString()}`}
            >
              Prize Details
            </MenuItemLink>
          )}
          {/* When the score legend opens, close the menu */}
          <ScoreLegendLink game={game} onAfterOpen={props.onRequestClose} />
          <MenuDivider />
        </>
      )}
    </AppMenu>
  );
};

const ScoreLegendLink = ({
  game,
  onAfterOpen,
}: {
  game: ApiGameDetails;
  onAfterOpen: () => void;
}) => {
  const Modal = useMemo(
    () => (props: Omit<ComponentProps<typeof ScoreLegendModal>, "game">) => (
      <ScoreLegendModal game={game} {...props} />
    ),
    [game],
  );

  return (
    <ModalToggle Modal={Modal} onAfterOpen={onAfterOpen}>
      <MenuItem Icon={ScoreIcon}>Score Legend</MenuItem>
    </ModalToggle>
  );
};
