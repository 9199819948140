import { Text } from "@sidelinesports/rebass";
import { ApiGameDetails } from "../../../api";
import { to12HrTime, getShortTZ, getDateStringOrToday } from "../../../shared";

export const GameTimeIndicator = ({ game }: { game: ApiGameDetails }) => {
  return (
    <Text
      variant="h300"
      style={{
        whiteSpace: "nowrap",
        fontWeight: "normal",
        opacity: "0.64",
        textTransform: "capitalize",
      }}
    >
      {getDateStringOrToday(game.timestamp)} {to12HrTime(game.timestamp)}{" "}
      {getShortTZ()}
    </Text>
  );
};
