import { keyframes } from "@emotion/react";
import { Box, BoxProps } from "@sidelinesports/rebass";

export const PulsingCircle = ({ sx, ...props }: Omit<BoxProps, "css">) => (
  <Box
    display="inline-block"
    backgroundColor="white"
    height="0.7em"
    width="0.7em"
    sx={{
      animation: `3s ${pulse} infinite`,
      borderRadius: "50%",
      transform: "scale(1)",
      ...sx,
    }}
    {...props}
  />
);

const pulse = keyframes`
0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
}

20% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
}

70%, 100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
}
`;
