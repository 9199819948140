import { ApiPlayerDetails } from "../../api";
import { ResponsiveImage, ResponsiveImageProps } from "../Image";

export const PLAYER_HEADSHOT_RESIZE_RATIO = "2:3";

export const HeadshotBackground = ({
  player,
  sx,
  ...props
}: {
  player: Pick<ApiPlayerDetails, "firstName" | "lastName" | "headshot">;
} & Omit<ResponsiveImageProps, "alt" | "source">) => {
  return (
    <ResponsiveImage
      alt={`Picture of ${player.firstName} ${player.lastName}`}
      resize={{ ratio: PLAYER_HEADSHOT_RESIZE_RATIO }}
      source={player.headshot}
      sx={{
        ...sx,
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        height: "100%",
        width: "100%",
        objectFit: "cover",
      }}
      {...props}
    />
  );
};
